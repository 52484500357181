import React, { MouseEvent } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import Breadcrumb, { BreadcrumbProps } from './Breadcrumb'
import BackButton from 'common/BackButton'

export interface BreadcrumbsProps {
  className?: string
  disabled?: boolean
  entries: BreadcrumbProps[]
  onBack?: (e: MouseEvent<HTMLButtonElement>) => void
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledUl = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;

  padding: 0;

  list-style: none;
`

const StyledBackButton = styled(BackButton)`
  margin: 0 ${token('space-m')} 0 0;
`

function Breadcrumbs({ entries, onBack, disabled, ...others }: BreadcrumbsProps): JSX.Element {
  return (
    <StyledWrapper {...others}>
      {onBack && (
        <StyledBackButton data-testid="breadcrumbs-back" onClick={onBack} disabled={disabled} />
      )}

      <StyledUl data-testid="breadcrumbs">
        {(entries || []).map((entry: BreadcrumbProps) => {
          return <Breadcrumb key={entry.label} {...entry} />
        })}
      </StyledUl>
    </StyledWrapper>
  )
}
export default Breadcrumbs
