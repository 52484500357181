import React, { ReactNode } from 'react'
import styled from 'styled-components'

import transition from 'styles/transition'

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  & > svg {
    ${transition({
      property: 'transform',
    })}

    color: currentColor;

    fill: currentColor;
  }
`

export interface IconProps<T> {
  name: keyof T
  size?: string | number
  width?: string | number
  height?: string | number
  className?: string
}

export type IconMapping = Record<string, JSX.Element | ((props: never) => JSX.Element)>

export function UnknownIcon(): JSX.Element {
  return <span aria-label="Unknown icon">&#9785;</span>
}

function IconFactory(map: IconMapping): (props: IconProps<typeof map>) => JSX.Element {
  function getIcon(name: keyof typeof map): ReactNode | null {
    // eslint-disable-next-line no-param-reassign
    name = String(name || '').toLowerCase()

    if (!(name in map)) return UnknownIcon

    return map[name]
  }

  return function Icon(props: IconProps<typeof map>) {
    const { name, size, width = '1em', height = '1em', className, ...rest } = props

    const I = getIcon(name) as typeof React.Component
    return (
      <Wrapper className={className}>
        <I width={size || width} height={size || height} {...rest} />
      </Wrapper>
    )
  }
}

export default IconFactory
