import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'

import { Modal } from '../Modal'
import { Button } from '../Button'
import type { ButtonProps } from '../Button'
import conditional, { whenProps } from 'tools/conditional'

interface WithScaleProps {
  scale?: 'small' | 'default' | 'large'
}

export interface DialogProps extends WithScaleProps {
  className?: string
  id?: string
  open: boolean
  children: ReactNode
  onOverlayClick?: () => void
}

export interface ContentProps extends WithScaleProps {
  id?: string
}

const Header = styled.h1`
  padding-bottom: ${token('space-s')};

  color: ${token('dialog-header-color')};
  font-weight: ${token('font-weight-bold')};
  font-size: ${token('font-size-2')};
  line-height: ${token('font-height-2')};
  text-align: center;

  border-bottom: 1px solid ${token('dialog-header-border-color')};
`
const Body = styled.div`
  margin-top: ${token('space-l')};
  margin-bottom: ${token('space-l')};

  color: ${token('dialog-body-font-color')};
  font-size: ${token('font-size-4')};
  line-height: ${token('font-height-3')};
  text-align: center;
`

const Content = styled.div<ContentProps>`
  padding: ${token('space-xl')};

  background: white;
  border-radius: ${token('border-radius-s')};
  box-shadow: ${token('shadow-modal')};
`

const ActionWrapper = styled.div`
  button {
    width: 100%;
    margin-bottom: ${token('space-s')};
  }
`

const StyledDialogActions = styled.div<{ $scale: string; $direction: string }>`
  display: flex;
  flex-flow: ${conditional({
    'row-reverse': whenProps({ $direction: 'horizontal' }),
    column: whenProps({ $direction: 'vertical' }),
  })};
  justify-content: ${conditional({
    center: whenProps({ $scale: 'small' }),
    end: whenProps({ $scale: ['default', 'large'] }),
  })};

  ${ActionWrapper}:last-child {
    margin-right: ${conditional({
      'space-m': whenProps({ $direction: 'horizontal' }),
      initial: whenProps({ $direction: 'vertical' }),
    })}};
  }
`

function DialogActions({
  scale = 'default',
  direction = 'vertical',
  children,
}: {
  scale?: 'small' | 'default' | 'large'
  direction?: 'vertical' | 'horizontal'
  children: ReactNode
}) {
  return (
    <StyledDialogActions $scale={scale} $direction={direction}>
      {children}
    </StyledDialogActions>
  )
}

export interface DialogActionConfirmProps extends Omit<ButtonProps, 'onClick' | 'variant'> {
  onConfirm: () => void
}

function DialogActionConfirm({
  onConfirm = () => void 0,
  children = 'CONFIRM',
  ...others
}: DialogActionConfirmProps) {
  return (
    <ActionWrapper>
      <Button {...others} onClick={onConfirm} variant="primary">
        {children}
      </Button>
    </ActionWrapper>
  )
}

export interface DialogActionCancelProps extends Omit<ButtonProps, 'onClick' | 'variant'> {
  onCancel: () => void
}

function DialogActionCancel({
  onCancel = () => void 0,
  children = 'CANCEL',
  ...others
}: DialogActionCancelProps) {
  return (
    <ActionWrapper>
      <Button {...others} onClick={onCancel} variant="secondary">
        {children}
      </Button>
    </ActionWrapper>
  )
}

function Dialog({ scale = 'default', children, open, ...others }: DialogProps): JSX.Element {
  return (
    <Modal open={open} scale={scale} {...others}>
      <Content>{children}</Content>
    </Modal>
  )
}

Dialog.Header = Header
Dialog.Body = Body
Dialog.ActionConfirm = DialogActionConfirm
Dialog.ActionCancel = DialogActionCancel
Dialog.Actions = DialogActions

export default Dialog
