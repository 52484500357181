import React, { Fragment, InputHTMLAttributes, ReactNode } from 'react'
import clsx from 'clsx'

import { Icon } from 'components/Icon'
import ColorScheme from 'utils/types/ColorScheme'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import SelectionWrapper, { SelectionStyleProps } from 'common/SelectionWrapper'
import styled from 'styled-components'
import { getToken as token } from 'theming'
import transition from 'styles/transition'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement>, SelectionStyleProps {
  leading?: ReactNode
  scheme?: ColorScheme
  trailing?: ReactNode
}

const Check = styled(Icon)<SelectionStyleProps>`
  position: absolute;
  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);

  pointer-events: none;
`

const Selector = styled.input<SelectionStyleProps>`
  ${transition()}

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  cursor: pointer;

  appearance: none;

  outline: none;

  color: white;

  height: ${token('checkbox-selector-size')};
  width: ${token('checkbox-selector-size')};

  border-radius: ${token('checkbox-selector-border-radius')};
  border-width: ${token('button-border-width')};
  border-style: solid;

  border-color: ${conditional({
    'checkbox-selector-border-color': whenProps({ scheme: 'light' }),
    'checkbox-dark-selector-border-color': whenProps({ scheme: 'dark' }),
  })};

  background: ${conditional({
    'checkbox-selector-background': whenProps({ scheme: 'light' }),
    'checkbox-dark-selector-background': whenProps({ scheme: 'dark' }),
  })};

  :checked {
    background-color: ${token('checkbox-selector-checked-background')};
    border-color: ${token('checkbox-selector-checked-border-color')};
  }

  :checked + ${Check} {
    color: ${token('checkbox-selector-icon-color')};
  }

  :not(:checked) + ${Check} {
    color: transparent;
  }

  ${disableable`
    background: ${conditional({
      'checkbox-selector-background--disabled': whenProps({ scheme: 'light' }),
      'checkbox-dark-selector-background--disabled': whenProps({ scheme: 'dark' }),
    })};
    border-color: ${conditional({
      'checkbox-selector-border-color--disabled': whenProps({ scheme: 'light' }),
      'checkbox-dark-selector-border-color--disabled': whenProps({ scheme: 'dark' }),
    })};
    :checked {
      background-color: ${token('checkbox-selector-checked-background--disabled')};
      border-color: ${token('checkbox-selector-checked-border-color--disabled')};
    }
  `}

  ${hoverable`
    background: ${conditional({
      'checkbox-selector-background--hover': whenProps({ scheme: 'light' }),
      'checkbox-dark-selector-background--hover': whenProps({ scheme: 'dark' }),
    })};
    border-color: ${conditional({
      'checkbox-selector-border-color--hover': whenProps({ scheme: 'light' }),
      'checkbox-dark-selector-border-color--hover': whenProps({ scheme: 'dark' }),
    })};

    :checked {
      background-color: ${token('checkbox-selector-checked-background--hover')};
      border-color: ${token('checkbox-selector-checked-border-color--hover')};
    }

    :checked + ${Check} {
      color: ${token('checkbox-selector-icon-color')};
    }
  `}

  ${focusable`
    background: ${conditional({
      'checkbox-selector-background--focus': whenProps({ scheme: 'light' }),
      'checkbox-dark-selector-background--focus': whenProps({ scheme: 'dark' }),
    })};
    border-color: ${conditional({
      'checkbox-selector-border-color--focus': whenProps({ scheme: 'light' }),
      'checkbox-dark-selector-border-color--focus': whenProps({ scheme: 'dark' }),
    })};

    :checked {
      background-color: ${token('checkbox-selector-checked-background--focus')};
      border-color: ${token('checkbox-selector-checked-border-color--focus')};
    }
    :checked + ${Check} {
      color: ${token('checkbox-selector-icon-color')};
    }

    box-shadow: ${token('checkbox-selector-outline')};
  `}
`

function Checkbox({
  className,
  disabled,
  children,
  leading,
  trailing,
  scheme = 'light',
  scale = 'default',
  ...others
}: CheckboxProps): JSX.Element {
  return (
    <SelectionWrapper
      scheme={scheme}
      scale={scale}
      selector={
        <Fragment>
          <Selector
            data-testid="selector"
            {...others}
            scheme={scheme}
            disabled={disabled}
            type="checkbox"
          />
          <Check name="check" size={14} className="check" />
        </Fragment>
      }
      leading={leading}
      trailing={trailing}
      className={clsx({ 'is-disabled': disabled }, className)}
      data-testid="checkbox"
    >
      {children}
    </SelectionWrapper>
  )
}

export default Checkbox
