/* eslint-disable react/prop-types */
import React, { Fragment, forwardRef } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { Dropdown, GenericDropdown } from 'components/Dropdown'
import { Empty as DefaultEmpty } from 'components/Empty'
import { getToken as token } from 'theming'
import { Icon as DefaultIcon } from 'components/Icon'
import { SelectableContext, SelectContext } from './Select.context'
import { Tag } from 'components/Tag'
import { Text } from 'components/Text'
import CloseButton from 'common/CloseButton'
import highlightMatch from 'utils/toolset/highlightMatch'
import isEmpty from 'utils/toolset/isEmpty'
import omit from 'utils/toolset/omit'
import pluralize from 'utils/toolset/pluralize'
import SelectTrigger from './SelectTrigger'
import toArray from 'utils/toolset/toArray'
import useSelect from './useSelect'
import SelectOption, { DefaultSelectOption } from './SelectOption'

import type { DropdownMenuProps } from 'components/Dropdown'
import type {
  SelectProps,
  useSelectReturn,
  SelectableOption,
  SelectableOptionProps,
  SelectStatus,
} from './Select.types'

const OMITTED_PROPS = ['id', 'name', 'datasources', 'onChange', 'onQueryChange', 'options', 'value']

const Empty = styled(DefaultEmpty)`
  margin: 0 ${token('space-s')};
`

const Icon = styled(DefaultIcon)`
  color: ${token('color-accent')};
`

const Loading = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 2em;

  height: 2em;
  padding: ${token('space-xs')};

  font-size: ${token('font-size-3')};
`

function getCommonClearButtonProps() {
  return {
    'data-testid': 'select-trigger-clear',
  }
}

const SelectMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(function SelectMenu(
  props: DropdownMenuProps,
  ref
): JSX.Element {
  return <Dropdown.Menu ref={ref} {...props} role="listbox" />
})

function ClearMultiple({ select }: { select: useSelectReturn }) {
  const { onClick } = select.getClearProps()
  const total = toArray(select.value).length

  return (
    <div data-testid="select-trigger-clear-counter">
      <Tag
        removable
        size="default"
        variant="accent"
        onRemove={onClick}
        title={`${total} selected option${pluralize(total)}`}
        getRemoveButtonProps={getCommonClearButtonProps}
        disabled={select.disabled}
      >
        {total}
      </Tag>
    </div>
  )
}

const HighlightMatch = styled(({ label, query, ...props }: { label: string; query: string }) => (
  <span {...props}>{highlightMatch(label, query)}</span>
))`
  /* highlightMatch splits the label into separate HTML elements, thus we need
  to preserve white-spaces, otherwise the browser wouldn't render them. */
  white-space: pre;

  mark {
    font-weight: ${token('font-weight-bold')};

    background-color: ${token('color-transparent')};
  }
`

const Divider = styled.hr`
  width: calc(100% - ${token('space-m')});
  height: 1px;
  margin: ${token('space-m')} ${token('space-s')};

  background-color: ${token('color-neutral-lighter')};
  border: 0;
`

const DividerText = styled(Text)`
  position: absolute;
  top: ${token('space-s')};

  padding-right: ${token('space-xs')};
  padding-left: ${token('space-m')};

  background-color: ${token('color-neutral-white')};
`

type SeparatorProps = {
  status: SelectStatus
  after?: unknown[]
  before?: unknown[]
}

function Separator({ status, after = [], before = [] }: SeparatorProps): JSX.Element {
  if (status === 'idle') {
    if (isEmpty(after) && !isEmpty(before)) return <Divider />
    if (isEmpty(after)) return <Fragment />
  }

  return (
    <div style={{ position: 'relative' }}>
      <Divider />
      <DividerText variant="caption-bold" color="color-neutral-light">
        {status === 'querying' ? 'Loading...' : `${after.length} option${pluralize(after.length)}`}
      </DividerText>
    </div>
  )
}

function renderOptionsSingle(select: useSelectReturn): JSX.Element {
  if (select.status === 'querying' && isEmpty(select.options)) {
    return <Empty>Loading...</Empty>
  }

  if (isEmpty(select.options)) {
    return <Empty>No results found.</Empty>
  }

  return (
    <Fragment>
      {select.options.map((option) => {
        const { label, value, checked } = select.getOption(option)

        return (
          <DefaultSelectOption
            key={value}
            {...select.getOptionProps({ option })}
            trailing={checked && <Icon name="check" />}
          >
            <HighlightMatch label={label} query={select.query} />
          </DefaultSelectOption>
        )
      })}
    </Fragment>
  )
}

type SelectableOptionMulti = {
  option: SelectableOption
  props: SelectableOptionProps
}

function renderOptionsMultiple(select: useSelectReturn): JSX.Element {
  const selectedOptions = Array.from(select.selectable.selected.values()).map((selectable) => {
    const option = select.getOption(selectable)
    const props = select.getOptionProps({ option: selectable })

    return { option, props }
  })

  const remainingOptions = select.options.reduce((remaining, selectable) => {
    const option = select.getOption(selectable)

    if (option.checked) {
      return remaining
    }

    const props = select.getOptionProps({ option: selectable })

    const entry = { option, props }

    return [...remaining, entry]
  }, [] as SelectableOptionMulti[])

  let remaining = (
    <Fragment>
      {remainingOptions.map(({ option, props }) => (
        <DefaultSelectOption
          key={option.value}
          {...props}
          leading={<Checkbox scale="small" defaultChecked={false} />}
        >
          <HighlightMatch label={option.label} query={select.query} />
        </DefaultSelectOption>
      ))}
    </Fragment>
  )

  if (select.status !== 'querying' && isEmpty(remainingOptions)) {
    remaining = <Empty>No more options.</Empty>
  }

  if (select.status !== 'querying' && isEmpty(select.options)) {
    remaining = <Empty>No results found.</Empty>
  }

  return (
    <Fragment>
      {selectedOptions.map(({ option, props }) => (
        <DefaultSelectOption
          key={option.value}
          {...props}
          leading={<Checkbox scale="small" defaultChecked />}
        >
          {option.label}
        </DefaultSelectOption>
      ))}
      <Separator status={select.status} before={selectedOptions} after={remainingOptions} />
      {remaining}
    </Fragment>
  )
}

function Select(props: SelectProps): JSX.Element {
  const { multiple, placeholder, children, ...others } = props

  const select = useSelect(props)

  function renderOptions() {
    if (multiple) {
      return renderOptionsMultiple(select)
    }

    return renderOptionsSingle(select)
  }

  function getTrailing() {
    if (select.status === 'querying') {
      return <Loading data-testid="select-trigger-loading">&middot;&middot;&middot;</Loading>
    }

    if (select.value) {
      if (multiple) {
        return <ClearMultiple select={select} />
      }

      if (!select.disabled) {
        return (
          <CloseButton
            size={12}
            {...getCommonClearButtonProps()}
            {...select.getClearProps()}
            type="button"
          />
        )
      }
    }

    return null
  }

  const shouldRenderChildren = children && select.selectable.type() === 'single'

  return (
    <GenericDropdown {...omit(others, OMITTED_PROPS)} {...select.getDropdownProps()}>
      <SelectableContext.Provider value={select.selectable}>
        <SelectTrigger
          {...select.getTriggerProps()}
          trailing={getTrailing()}
          placeholder={placeholder}
          autoComplete="off"
        />
        <SelectMenu {...select.getMenuProps()}>
          {shouldRenderChildren ? (
            <SelectContext.Provider value={select}>{children}</SelectContext.Provider>
          ) : (
            renderOptions()
          )}
        </SelectMenu>
      </SelectableContext.Provider>
    </GenericDropdown>
  )
}

Select.Trigger = SelectTrigger
Select.Option = SelectOption

export default Select
