import React, { Fragment, HTMLAttributes, PropsWithChildren, ReactNode } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { identity } from '@loadsmart/utils-function'

import font from 'styles/font'
import { getToken as token } from 'theming'
import { Icon } from 'components/Icon'
import conditional, { whenProps } from 'tools/conditional'
import hoverable from 'styles/hoverable'
import focusable from 'styles/focusable'
import transition from 'styles/transition'
import ellipsizable from 'styles/ellipsizable'
import hidden from 'styles/hidden'

import { AccordionProvider, useAccordionContext } from './Accordion.context'

const StyledWrapper = styled.article<{ open: boolean }>`
  background: ${token('color-neutral-white')};
  border: 1px solid
    ${conditional({
      'color-neutral-light': whenProps({ open: false }),
      'color-accent': whenProps({ open: true }),
    })};
  border-radius: ${token('border-radius-s')};

  ${hoverable`
    border-color: ${token('color-accent')};
  `}
`

const StyledToggle = styled.button<{ open: boolean }>`
  ${font({
    height: 'font-height-2',
  })}

  ${transition()}

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: ${token('font-size-3')};
  font-weight: ${conditional({
    'font-weight-regular': whenProps({ open: false }),
    'font-weight-bold': whenProps({ open: true }),
  })};
  text-align: left;

  cursor: pointer;

  color: ${token('color-neutral-darker')};

  background-color: ${token('color-transparent')};
  border-radius: ${token('border-radius-s')};
  border: none;

  padding: ${token('space-s')} ${token('space-m')};

  width: 100%;

  ${hoverable`
    background: ${token('color-neutral-lighter')};
  `}

  ${focusable`
    box-shadow: ${token('shadow-glow-primary')};
  `}
`

const StyledContent = styled.section<{ open: boolean }>`
  ${font({
    height: 'font-height-2',
    weight: 'font-weight-medium',
  })}

  padding-left: ${conditional({
    '0': whenProps({ open: false }),
    'space-m': whenProps({ open: true }),
  })};
  padding-right: ${conditional({
    '0': whenProps({ open: false }),
    'space-m': whenProps({ open: true }),
  })};
  padding-top: ${conditional({
    '0': whenProps({ open: false }),
    'space-xl': whenProps({ open: true }),
  })};
  padding-bottom: ${conditional({
    '0': whenProps({ open: false }),
    'space-s': whenProps({ open: true }),
  })};

  overflow-x: hidden;
  overflow-y: auto;
`

const HiddenInput = styled.input`
  ${hidden()}

  &:not(:checked) + ${StyledContent} {
    height: 0;
  }

  &:checked + ${StyledContent} {
    height: 100%;
  }
`

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`

const Leading = styled(StyledSpan)`
  margin: 0 ${token('space-xs')} 0 0;
`

const Trailing = styled(StyledSpan)`
  margin: 0 0 0 auto;
`

const Children = styled.span`
  pointer-events: none;

  ${ellipsizable()}
`

function ToggleIndicator({ open }: { open: boolean }) {
  return <Icon name={open ? 'minus' : 'plus'} size={16} />
}

function AccordionWrapper({
  children,
  ...others
}: PropsWithChildren<HTMLAttributes<HTMLElement>>): JSX.Element {
  const [open] = useAccordionContext()
  return (
    <StyledWrapper data-testid="accordion" open={open} {...others}>
      {children}
    </StyledWrapper>
  )
}

export type AccordionToggleProps = {
  leading?: ReactNode
}

function AccordionToggle({
  leading,
  children,
}: PropsWithChildren<AccordionToggleProps>): JSX.Element {
  const [open, toggle] = useAccordionContext()

  return (
    <StyledToggle open={open} onClick={toggle} type="button">
      {leading && <Leading>{leading}</Leading>}
      <Children>{children}</Children>
      <Trailing data-testid="accordion-toggle">
        <ToggleIndicator open={open} />
      </Trailing>
    </StyledToggle>
  )
}

export type AccordionBodyProps = HTMLAttributes<HTMLElement>

function AccordionBody({ children }: PropsWithChildren<AccordionBodyProps>): JSX.Element {
  const [open] = useAccordionContext()

  return (
    <Fragment>
      <HiddenInput type="checkbox" checked={open} onChange={identity} />
      <StyledContent
        open={open}
        className={clsx({
          'is-open': open,
        })}
        aria-hidden={!open ? 'true' : 'false'}
        data-testid="accordion-content"
      >
        {children}
      </StyledContent>
    </Fragment>
  )
}

export interface AccordionProps extends HTMLAttributes<HTMLElement> {
  expanded?: boolean
}

function Accordion({ children, expanded, ...others }: AccordionProps): JSX.Element {
  return (
    <AccordionProvider expanded={expanded}>
      <AccordionWrapper {...others}>{children}</AccordionWrapper>
    </AccordionProvider>
  )
}

Accordion.Toggle = AccordionToggle
Accordion.Body = AccordionBody

export default Accordion
