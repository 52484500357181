import { isFunction } from '@loadsmart/utils-function'
import isEmpty from 'utils/toolset/isEmpty'

export type DateHelperOptions = {
  normalize?: boolean
}

export const DEFAULT_OPTIONS: DateHelperOptions = {
  normalize: true,
}

export type InputDate = string | number | Date

export type DateConstraint =
  | InputDate
  | [InputDate, InputDate]
  | [null, InputDate]
  | [InputDate, null]

export type FunctionConstraint = (day: CalendarDate) => boolean

export type CalendarConstraint = DateConstraint | FunctionConstraint

export interface CalendarDate {
  /**
   * Get `Date` object representing this date.
   * @returns {Date}
   */
  get(): Date
  /**
   * Get the year using Universal Coordinated Time (UTC).
   * @returns {number}
   */
  getYear(): number
  /**
   * Gets the month using Universal Coordinated Time (UTC).
   * @returns {number}
   */
  getMonth(): number
  /**
   * Get the day-of-the-month, using Universal Coordinated Time (UTC).
   * @returns {number}
   */
  getDate(): number
  /**
   * Get the time value in milliseconds.
   *
   * [!] Be aware that it might be normalized to 12:00:00 (UTC), if you did not call the helper with
   * `options.normalize: false`.
   *
   * @returns {number}
   */
  getTime(): number
  /**
   * Get the day of the week.
   *
   * [!] Be aware that it might be normalized to 12:00:00 (UTC), if you did not call the helper with
   * `options.normalize: false`.
   *
   * @returns {number}
   */
  getWeekday(): number
  /**
   * Get the hours value.
   *
   * [!] Be aware that it might be normalized to 12:00:00 (UTC), if you did not call the helper with
   * `options.normalize: false`.
   *
   * @returns {number}
   */
  getHours(): number
  /**
   * Get the minutes value.
   *
   * [!] Be aware that it might be normalized to 12:00:00 (UTC), if you did not call the helper with
   * `options.normalize: false`.
   *
   * @returns {number}
   */
  getMinutes(): number
  /**
   * Get the seconds value.
   *
   * [!] Be aware that it might be normalized to 12:00:00 (UTC), if you did not call the helper with
   * `options.normalize: false`.
   *
   * @returns {number}
   */
  getSeconds(): number
  /**
   * Check if the current date matches **at least one** of the provided constraints.
   * @param {...CalendarConstraint[]} constraintsArg
   * @returns {boolean}
   */
  matches(...constraints: CalendarConstraint[]): boolean
  /**
   * Set the given `value` to the `key` property of this instance.
   * This function mutates the internal date instance.
   * @param {('year' | 'month' | 'day')} key
   * @param {number} value
   * @returns {CalendarDate}
   */
  set(key: 'year' | 'month' | 'day', value: number): CalendarDate
  /**
   * Return a copy of this instance, adding the given `value` to the `key` property.
   * @param {('year' | 'month' | 'day')} key
   * @param {number} value
   * @returns {CalendarDate}
   */
  add(key: 'year' | 'month' | 'day', value: number): CalendarDate
  /**
   * Return a copy of this instance, subtracting the given `value` from the `key` property.
   * @param {('year' | 'month' | 'day')} key
   * @param {number} value
   * @returns {CalendarDate}
   */
  subtract(key: 'year' | 'month' | 'day', value: number): CalendarDate
  /**
   * Compare this date with the given `other`.
   * @param {('>=' | '>' | '=' | '<' | '<=')} operator - type of comparison to be performed.
   * @param {CalendarDate} other - Other `CalendarDate` to compare with.
   * @param {('year' | 'month' | 'day')} [precision] - notice that the precision increases with the evaluated period, so year < month < day < hour < minute < second < millisecond. Default is 'day'.
   * @return {boolean} comparison result
   */
  is(operator: '>=' | '>' | '=' | '<' | '<=', other: CalendarDate, precision?: string): boolean
  /**
   * Return this date as a string value in ISO format.
   * @returns {string}
   */
  toString(): string
}

export type CalendarDateRange = [CalendarDate | null, CalendarDate | null]

/**
 * Ideally, follow the date/time string formats:
 * * `YYYY-MM-DD`
 * * `YYYY-MM-DDTHH:mm:ss.sssZ`
 * * `YYYY-MM-DDTHH:mm:ss.sss+00:00`
 *
 * `dateArg` is expected to have timezone information or to be UTC.
 *
 * By default, we normalize the input date to 12:00:00 (UTC); this simplifies comparison of dates; be mindful
 * of this when using this helper for time relate logic.
 * You can disable this behavior by passing `options.normalize: false`.
 *
 * @param {InputDate} [dateArg]
 * @return {CalendarDate}
 */
function DateHelper(dateArg?: InputDate, optionsArg = DEFAULT_OPTIONS): CalendarDate {
  const options = { ...DEFAULT_OPTIONS, ...optionsArg }
  const utcDate = (function normalizeToUTC() {
    let date = new Date()

    if (dateArg != null) {
      date = new Date(dateArg)
    }

    // create a date with local timezone based on the UTC input date
    const utcDate = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        options.normalize ? 12 : date.getUTCHours(),
        options.normalize ? 0 : date.getUTCMinutes(),
        options.normalize ? 0 : date.getUTCSeconds(),
        options.normalize ? 0 : date.getUTCMilliseconds()
      )
    )

    return utcDate
  })()

  return {
    get() {
      return utcDate
    },
    getYear() {
      return utcDate.getUTCFullYear()
    },
    getMonth() {
      return utcDate.getUTCMonth()
    },
    getDate() {
      return utcDate.getUTCDate()
    },
    getTime() {
      return utcDate.getTime()
    },
    getWeekday() {
      return utcDate.getUTCDay()
    },
    getHours() {
      return utcDate.getUTCHours()
    },
    getMinutes() {
      return utcDate.getUTCMinutes()
    },
    getSeconds() {
      return utcDate.getUTCSeconds()
    },
    matches(...constraints: CalendarConstraint[]): boolean {
      if (isEmpty(constraints)) {
        return false
      }

      const constraintEvaluators = constraints.map(getConstraintEvaluator)
      const date = DateHelper(utcDate)

      return constraintEvaluators.some((evaluator) => {
        return evaluator(date)
      })
    },
    add(key: 'year' | 'month' | 'day', value: number) {
      return add(utcDate, key, value)
    },
    subtract(key: 'year' | 'month' | 'day', value: number) {
      return add(utcDate, key, -1 * value)
    },
    set(key: 'year' | 'month' | 'day', value: number) {
      const newValue = {
        year: utcDate.getUTCFullYear(),
        month: utcDate.getUTCMonth(),
        day: utcDate.getUTCDate(),
        [key]: value,
      }

      utcDate.setUTCFullYear(newValue.year)
      utcDate.setUTCMonth(newValue.month)
      utcDate.setUTCDate(newValue.day)

      return this
    },
    is(
      operator: '>=' | '>' | '=' | '<' | '<=',
      other: CalendarDate,
      precision?: 'year' | 'month' | 'day'
    ): boolean {
      function compare(a: number, b: number) {
        let result

        switch (operator) {
          case '>=':
            result = a >= b
            break
          case '>':
            result = a > b
            break
          case '<':
            result = a < b
            break
          case '<=':
            result = a <= b
            break
          default:
            result = a === b
            break
        }

        return result
      }

      return compare(
        Number(getComparableDate(utcDate, precision)),
        Number(getComparableDate(other.get(), precision))
      )
    },
    toString(): string {
      return utcDate.toISOString()
    },
  }
}

function getComparableDate(date: Date, precision: 'year' | 'month' | 'day' = 'day') {
  const COMPARE_TO = {
    year: 1,
    month: 2,
    day: 3,
  }

  return [date.getUTCFullYear(), padded(date.getUTCMonth()), padded(date.getUTCDate())]
    .slice(0, COMPARE_TO[precision])
    .join('')
}

/**
 * Constraints represent ranges of dates, inclusive in both ends.
 * Returns an array representing the initial and final timestamps (after the transformations applied by `DateHelper`).
 *
 * @example
 * ```js
 * // to represent a range that starts and ends in the same date
 * getConstraintRange(1643371200000) // returns [1643371200000, 1643371200000]
 * getConstraintRange([1643371200000, 1643371200000]) // returns [1643371200000, 1643371200000]
 * getConstraintRange('2022-01-28T12:00:00.000Z') // returns [1643371200000, 1643371200000]
 * getConstraintRange(['2022-01-28T12:00:00.000Z', '2022-01-28T12:00:00.000Z']) // returns [1643371200000, 1643371200000]
 *
 * // to represent a range that starts at one date and ends at another
 * getConstraintRange([1641038400000, 1643371200000]) // returns [1641038400000, 1643371200000]
 * getConstraintRange(['2022-01-01T12:00:00.000Z', '2022-01-28T12:00:00.000Z']) // returns [1641038400000, 1643371200000]
 *
 * // to represent a range that starts at one date and has no end
 * getConstraintRange([1641038400000, null]) // returns [1641038400000, `MAX_SUPPORTED_DATE`]
 * getConstraintRange(['2022-01-01T12:00:00.000Z', null]) // returns [1641038400000, `MAX_SUPPORTED_DATE`]
 *
 * // to represent a range that ends at one date and has no start
 * getConstraintRange([null, 1643371200000]) // returns [0, 1643371200000]
 * getConstraintRange([null, '2022-01-28T12:00:00.000Z']) // returns [0, 1643371200000]
 * ```
 * @param rangeArg
 * @returns
 */
export function getConstraintRange(rangeArg: DateConstraint): [number, number] {
  let range: [number, number]

  if (!Array.isArray(rangeArg)) {
    const timestamp = DateHelper(rangeArg).getTime()
    range = [timestamp, timestamp]
  } else {
    const rangeStart = rangeArg[0] != null ? DateHelper(rangeArg[0]) : MIN_SUPPORTED_DATE
    const rangeEnd = rangeArg[1] != null ? DateHelper(rangeArg[1]) : MAX_SUPPORTED_DATE

    range = [rangeStart.getTime(), rangeEnd.getTime()]
  }

  return range
}

/**
 * Add the given `value` to the provided `key` of the provided `date`.
 * @param {Date} date - Date where the operation should be performed.
 * @param {('year' | 'month' | 'day')} key - period
 * @param {number} value - value to be added
 * @returns {Date} new date after the operation.
 */
function add(date: Date, key: 'year' | 'month' | 'day', value: number): CalendarDate {
  const increment = {
    year: 0,
    month: 0,
    day: 0,
    [key]: value,
  }

  const newDate = new Date(
    date.getUTCFullYear() + increment.year,
    date.getUTCMonth() + increment.month,
    date.getUTCDate() + increment.day,
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )

  return DateHelper(newDate)
}

/**
 * Wrap range constraint into a function and return a function constraint untouched.
 * @param {CalendarConstraint} constraint
 * @returns {(day: CalendarDate) => boolean}
 */
export function getConstraintEvaluator(
  constraint: CalendarConstraint
): (day: CalendarDate) => boolean {
  if (isFunction(constraint)) {
    return constraint
  }

  const [start, end] = getConstraintRange(constraint)

  return function matches(day: CalendarDate): boolean {
    if (start > end) {
      throw new InvalidDateConstraintError([start, end])
    }

    return start <= day.getTime() && day.getTime() <= end
  }
}

export function padded(value: number | string, maxLength = 2): string {
  return String(value).padStart(maxLength, '0')
}

export class InvalidDateConstraintError extends Error {
  constructor([start, end]: [number, number]) {
    super(`Invalid constraint: [${start}, ${end}]`)
  }
}

export const TODAY = DateHelper()

/**
 * Maximum and minimum supported `Date`.
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_ecmascript_epoch_and_timestamps
 */
export const MIN_SUPPORTED_DATE = DateHelper(0)
export const MAX_SUPPORTED_DATE = DateHelper(8.64e15 - 1)

export default DateHelper
