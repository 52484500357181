import React from 'react'
import { LabelHTMLAttributes } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { isNil } from '@loadsmart/utils-object'

import ColorScheme from 'utils/types/ColorScheme'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import ellipsizable from 'styles/ellipsizable'
import font from 'styles/font'
import { getToken as token } from 'theming'

import { Tooltip } from '../Tooltip'

type AcceptedType = 'label' | 'div' | 'span'

interface WithAdditionalProps {
  as?: AcceptedType
  disabled?: boolean
  required?: boolean
  scheme?: ColorScheme
  tip?: string
}

export interface LabelProps
  extends LabelHTMLAttributes<HTMLLabelElement | HTMLDivElement | HTMLSpanElement>,
    WithAdditionalProps {
  className?: string
}

const StyledWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  line-height: ${token('font-height-2')};
  ${disableable()}
`

const StyledChildren = styled.span<WithAdditionalProps>`
  display: inline-block;

  ${font({
    height: 'label-font-height',
    weight: 'label-font-weight',
  })}

  font-size: ${token('label-font-size')};
  color: ${conditional({
    'label-font-color': whenProps({ scheme: 'light' }),
    'color-neutral-white': whenProps({ scheme: 'dark' }),
  })};
  ${ellipsizable()}
`

const StyledRequired = styled.sup`
  top: 0;

  color: ${token('label-required-color')};
  font-size: ${token('label-font-size')};
`

const StyledTooltipAnchor = styled.span<WithAdditionalProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 0 0 0 ${token('label-tooltip-margin-left')};

  color: ${conditional({
    'label-tooltip-font-color': whenProps({ scheme: 'light' }),
    'label-tooltip-dark-font-color': whenProps({ scheme: 'dark' }),
  })};
  font-weight: ${token('label-tooltip-font-weight')};
  font-size: ${token('label-tooltip-font-size')};

  background: ${conditional({
    'label-tooltip-background-color': whenProps({ scheme: 'light' }),
    'label-tooltip-dark-background-color': whenProps({ scheme: 'dark' }),
  })};
  border-radius: ${token('border-radius-circle')};
`

function Label({
  as = 'label',
  children,
  className,
  disabled = false,
  required,
  scheme = 'light',
  tip,
  ...others
}: LabelProps): JSX.Element {
  const tag = String(as || 'label') as AcceptedType

  return (
    <StyledWrapper
      data-testid="label"
      as={tag}
      className={clsx(className, {
        'is-disabled': disabled,
      })}
      {...others}
    >
      <StyledChildren scheme={scheme}>{children}</StyledChildren>
      {required && <StyledRequired>*</StyledRequired>}
      {!isNil(tip) && (
        <Tooltip scheme={scheme} message={tip || ''}>
          <StyledTooltipAnchor scheme={scheme}>?</StyledTooltipAnchor>
        </Tooltip>
      )}
    </StyledWrapper>
  )
}

export default Label
