import { CustomTheme, getToken as token } from 'theming'
import { useTheme } from 'styled-components'
import useDialog from 'components/Dialog/useDialog'
import { useMemo } from 'react'
import useWindowResize from 'hooks/useWindowResize'
import { BASE_PIXELS } from 'utils/toolset/rem'

export interface useSideNavigationProps {
  open: boolean
  show: () => void
  hide: () => void
  toggle: () => void
}

function transformInPixels(value: string): number {
  if (value.indexOf('rem') >= 0) {
    return parseFloat(value) * BASE_PIXELS
  }

  return parseFloat(value)
}

function useSideNavigation(): useSideNavigationProps {
  const size = useWindowResize()
  const theme = useTheme() as CustomTheme

  const shouldOpen = useMemo(() => {
    const breakpoint = token('side-navigation-collapsing-breakpoint', { theme }) as string
    const breakpointInPixels = transformInPixels(breakpoint)
    return size.width > breakpointInPixels
  }, [size])

  return useDialog({ open: shouldOpen })
}

export default useSideNavigation
