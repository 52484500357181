import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSection,
  DropdownSeparator,
} from './DropdownMenu'
import { useClickOutside } from 'hooks/useClickOutside'
import DropdownContext from './Dropdown.context'
import DropdownTrigger from './DropdownTrigger'
import hidden from 'styles/hidden'
import useDropdown from './useDropdown'

import type { DropdownProps, GenericDropdownProps } from './Dropdown.types'

const DropdownWrapper = styled.div`
  position: relative;
`

const HiddenCloseButton = styled.button.attrs({
  type: 'button',
  tabIndex: -1,
})`
  ${hidden(true)}
`

// TODO: add focus trap here to allow navigating options with keyboard

/**
 * Generic dropdown component that allows composing its pieces as desired.
 *
 * Based on:
 * - https://www.w3.org/TR/2017/REC-wai-aria-1.1-20171214/#menu
 * - https://www.w3.org/WAI/tutorials/menus/application-menus/
 *
 * @param props
 * @returns
 */
export function GenericDropdown(props: GenericDropdownProps): JSX.Element {
  const { children, expanded, toggle, disabled = false, onBlur, ...others } = props
  const [contextValue, setContextValue] = useState({ expanded, toggle, disabled })
  const ref = useRef(null)

  useClickOutside(ref, function handleClickOutside() {
    onBlur?.()

    if (!expanded) {
      return
    }

    toggle()
  })

  useEffect(
    function updateContextValue() {
      setContextValue({ expanded, toggle, disabled })
    },
    [disabled, expanded, toggle]
  )

  return (
    <DropdownWrapper {...others} role="menubar" ref={ref}>
      <DropdownContext.Provider value={contextValue}>
        <HiddenCloseButton disabled={disabled} onClick={toggle}>
          Close
        </HiddenCloseButton>
        {children}
      </DropdownContext.Provider>
    </DropdownWrapper>
  )
}

function Dropdown(props: DropdownProps): JSX.Element {
  const dropdownProps = useDropdown(props)

  return <GenericDropdown {...props} {...dropdownProps} />
}

Dropdown.Trigger = DropdownTrigger
Dropdown.Menu = DropdownMenu
Dropdown.Section = DropdownMenuSection
Dropdown.Item = DropdownMenuItem
Dropdown.Separator = DropdownSeparator

export default Dropdown
