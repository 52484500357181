import React, { forwardRef } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import { getToken as token } from 'theming'
import { useFocusWithin } from 'hooks/useFocusWithin'
import ColorScheme from 'utils/types/ColorScheme'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import font from 'styles/font'
import hoverable from 'styles/hoverable'
import Status from 'utils/types/Status'
import transition from 'styles/transition'

import type { InputHTMLAttributes, ReactNode } from 'react'

interface WithAdditionalProps {
  scale?: 'small' | 'default' | 'large'
  status?: Status
  scheme?: ColorScheme
  leading?: ReactNode
  trailing?: ReactNode
}

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement>, WithAdditionalProps {
  className?: string
  children?: ReactNode
}

const InputWrapper = styled.div<WithAdditionalProps>`
  ${transition()}

  display: inline-flex;
  flex-flow: row nowrap;

  height: ${conditional({
    'text-field-height': whenProps({ scale: 'default' }),
    'text-field-small-height': whenProps({ scale: 'small' }),
    'text-field-large-height': whenProps({ scale: 'large' }),
  })};
  min-width: 0;

  ${font({
    height: 'text-field-font-height',
  })}

  font-size: ${conditional({
    'text-field-font-size': whenProps({ scale: 'default' }),
    'text-field-small-font-size': whenProps({ scale: 'small' }),
    'text-field-large-font-size': whenProps({ scale: 'large' }),
  })};

  border-radius: ${token('text-field-border-radius')};
  border-width: 1px;
  border-style: solid;

  background: ${conditional({
    'text-field-background': whenProps({ scheme: 'light' }),
    'text-field-dark-background': whenProps({ scheme: 'dark' }),
  })};

  border-color: ${conditional({
    'text-field-border-color': whenProps({ scheme: 'light', status: Status.Neutral }),
    'text-field-dark-border-color': whenProps({ scheme: 'dark', status: Status.Neutral }),
    'text-field-danger-border-color': whenProps({ status: Status.Danger }),
    'text-field-success-border-color': whenProps({ status: Status.Success }),
  })};

  color: ${conditional({
    'text-field-color': whenProps({ scheme: 'light' }),
    'text-field-dark-color': whenProps({ scheme: 'dark' }),
  })};

  ${hoverable`
    background: ${conditional({
      'text-field-background--hover': whenProps({ scheme: 'light' }),
      'text-field-dark-background--hover': whenProps({ scheme: 'dark' }),
    })};

    border-color: ${conditional({
      'text-field-border-color--hover': whenProps({ scheme: 'light' }),
      'text-field-dark-border-color--hover': whenProps({ scheme: 'dark' }),
    })};
  `}

  ${focusable`
    background: ${conditional({
      'text-field-background--focus': whenProps({ scheme: 'light' }),
      'text-field-dark-background--focus': whenProps({ scheme: 'dark' }),
    })};

    border-color: ${conditional({
      'text-field-border-color--focus': whenProps({ scheme: 'light' }),
      'text-field-dark-border-color--focus': whenProps({ scheme: 'dark' }),
    })};

    box-shadow: ${conditional({
      'text-field-outline': whenProps({ scheme: 'light' }),
      'text-field-dark-outline': whenProps({ scheme: 'dark' }),
    })};
  `}

  ${disableable()}
`

const StyledSpan = styled.span<WithAdditionalProps>`
  ${transition()}

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  color: ${conditional({
    'text-field-border-color': whenProps({ scheme: 'light', status: Status.Neutral }),
    'text-field-dark-border-color': whenProps({ scheme: 'dark', status: Status.Neutral }),
    'text-field-danger-border-color': whenProps({ status: Status.Danger }),
    'text-field-success-border-color': whenProps({ status: Status.Success }),
  })};
`

export const Leading = styled(StyledSpan)<WithAdditionalProps>`
  margin: 0 0 0
    ${conditional({
      'text-field-padding-x': whenProps({ scale: ['default', 'large'] }),
      'text-field-small-padding-x': whenProps({ scale: 'small' }),
    })};
`

export const Trailing = styled(StyledSpan)<WithAdditionalProps>`
  margin: 0
    ${conditional({
      'text-field-padding-x': whenProps({ scale: ['default', 'large'] }),
      'text-field-small-padding-x': whenProps({ scale: 'small' }),
    })}
    0 0;
`

const StyledInput = styled.input<WithAdditionalProps>`
  ${transition()}

  ${font({
    height: 'text-field-font-height',
  })}

  background: transparent;

  padding: ${conditional({
      'text-field-padding-y': whenProps({ scale: 'default' }),
      'text-field-small-padding-y': whenProps({ scale: 'small' }),
      'text-field-large-padding-y': whenProps({ scale: 'large' }),
    })}
    0;

  margin: 0
    ${conditional({
      'text-field-padding-x': whenProps({ scale: ['default', 'large'] }),
      'text-field-small-padding-x': whenProps({ scale: 'small' }),
    })};

  border: none;

  flex: 1;

  min-width: 0;

  ${focusable()}
  ${disableable()}

  /* suppress the clear button for IE */
  &[type="search"]::-ms-clear,
  &[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* suppress the clear button for Chromium-based browsers */
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  props: TextFieldProps,
  ref
): JSX.Element {
  const {
    disabled = false,
    status = Status.Neutral,
    scheme = 'light',
    scale = 'default',
    className,
    leading,
    trailing,
    ...others
  } = props
  const { ref: containerRef, focused } = useFocusWithin<HTMLDivElement>()

  return (
    <InputWrapper
      className={clsx(className, {
        'is-disabled': disabled,
        'is-focused': focused,
      })}
      scheme={scheme}
      status={status}
      scale={scale}
      ref={containerRef}
    >
      {leading && (
        <Leading status={status} scheme={scheme} scale={scale}>
          {leading}
        </Leading>
      )}
      <StyledInput
        ref={ref}
        type="text"
        data-testid="input"
        {...others}
        scale={scale}
        status={status}
        scheme={scheme}
        leading={leading}
        trailing={trailing}
        disabled={disabled}
      />
      {trailing && (
        <Trailing status={status} scheme={scheme} scale={scale}>
          {trailing}
        </Trailing>
      )}
    </InputWrapper>
  )
})

export default TextField
