import React, { HTMLAttributes, PropsWithChildren, ReactNode, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

import ColorScheme from 'utils/types/ColorScheme'
import focusable from 'styles/focusable'
import transition from 'styles/transition'
import font from 'styles/font'
import conditional, { whenProps } from 'tools/conditional'
import { getToken as token } from 'theming'

export enum TooltipPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export enum TooltipAlign {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  message: ReactNode
  scheme?: ColorScheme
  position?: TooltipPosition
  align?: TooltipAlign
}

type ContainerProps = Pick<TooltipProps, 'scheme'>

const Container = styled.div<ContainerProps>`
  display: inline-block;

  position: relative;

  cursor: help;

  ${focusable`
    outline: ${conditional({
      '$tooltip-outline': whenProps({ scheme: 'light' }),
      '$tooltip-dark-outline': whenProps({ scheme: 'dark' }),
    })};
  `}
`

type BubbleProps = Pick<TooltipProps, 'position' | 'align'>

const Bubble = styled.span<BubbleProps>`
  ${font({
    weight: 'font-weight-medium',
    height: 'font-height-3',
  })}

  ${transition()}

  white-space: initial;

  min-width: ${token('tooltip-min-width')};
  max-width: ${token('tooltip-max-width')};
  width: max-content;

  position: absolute;
  z-index: ${token('z-index-tooltip')};

  background: ${token('tooltip-background')};
  box-shadow: ${token('tooltip-shadow')};
  border-radius: ${token('tooltip-border-radius')};

  padding: ${token('tooltip-padding-y')} ${token('tooltip-padding-x')};

  color: ${token('tooltip-color')};
  font-size: ${token('tooltip-font-size')};
  line-height: ${token('tooltip-font-height')};

  ${({ position }) =>
    position === TooltipPosition.Top &&
    css`
      top: -16px;
      left: 50%;

      transform: ${conditional({
        'translate(-10%, -100%)': whenProps({ align: TooltipAlign.Start }),
        'translate(-50%, -100%)': whenProps({ align: TooltipAlign.Center }),
        'translate(-90%, -100%)': whenProps({ align: TooltipAlign.End }),
      })};
    `}

  ${({ position }) =>
    position === TooltipPosition.Bottom &&
    css`
      bottom: -16px;
      left: 50%;

      transform: ${conditional({
        'translate(-10%, 100%)': whenProps({ align: TooltipAlign.Start }),
        'translate(-50%, 100%)': whenProps({ align: TooltipAlign.Center }),
        'translate(-90%, 100%)': whenProps({ align: TooltipAlign.End }),
      })};
    `};

  ${({ position }) =>
    position === TooltipPosition.Left &&
    css`
      top: 50%;
      left: -16px;

      transform: translate(-100%, -50%);
    `};

  ${({ position }) =>
    position === TooltipPosition.Right &&
    css`
      top: 50%;
      right: -16px;

      transform: translate(100%, -50%);
    `};
`

type ArrowProps = Pick<TooltipProps, 'position'>
const Arrow = styled.span<ArrowProps>`
  width: 0;
  height: 0;

  background: transparent;
  border-style: solid;

  position: absolute;
  z-index: 1;

  ${({ position }) =>
    position === TooltipPosition.Top &&
    css`
      bottom: calc(100% - 4px);
      left: 50%;

      border-color: ${token('tooltip-background')} transparent transparent transparent;
      border-width: 12px 10px 0 10px;
      transform: translate(-50%, -100%);
    `}

  ${({ position }) =>
    position === TooltipPosition.Bottom &&
    css`
      top: calc(100% - 4px);
      left: 50%;

      border-color: transparent transparent ${token('tooltip-background')} transparent;
      border-width: 0 10px 12px 10px;
      transform: translate(-50%, 100%);
    `}

  ${({ position }) =>
    position === TooltipPosition.Left &&
    css`
      top: 50%;
      right: calc(100% - 4px);

      border-color: transparent transparent transparent ${token('tooltip-background')};
      border-width: 10px 0 10px 12px;
      transform: translate(-100%, -50%);
    `}

  ${({ position }) =>
    position === TooltipPosition.Right &&
    css`
      top: 50%;
      left: calc(100% - 4px);

      border-color: transparent ${token('tooltip-background')} transparent transparent;
      border-width: 10px 12px 10px 0;
      transform: translate(100%, -50%);
    `}
`

function Tooltip({
  children,
  message,
  scheme,
  position = TooltipPosition.Top,
  align = TooltipAlign.Center,
  ...rest
}: PropsWithChildren<TooltipProps>): JSX.Element {
  const [visible, setVisible] = useState(false)

  const showTooltip = useCallback(() => {
    setVisible(true)
  }, [])

  const hideTooltip = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <Container
      {...rest}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      tabIndex={0}
      scheme={scheme}
    >
      {visible && (
        <>
          <Bubble role="tooltip" position={position} align={align}>
            {message}
          </Bubble>
          <Arrow position={position} />
        </>
      )}
      {children}
    </Container>
  )
}

export default Tooltip
