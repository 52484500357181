import { AnchorHTMLAttributes } from 'react'
import { isBlank } from '@loadsmart/utils-string'

const INSECURE_HREF = /^javascript:/i

function checkHref(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { href, ...others } = props

  if (isBlank(href || '')) {
    return props
  }

  if (INSECURE_HREF.test(`${href || ''}`)) {
    // security risk, thus, removing it
    return others
  }

  return props
}

function checkTarget({ target, rel }: AnchorHTMLAttributes<HTMLAnchorElement>) {
  if (isBlank(target || '')) {
    return { target, rel }
  }

  const sanitizedRel = new Set((rel || '').split(/\s+/).filter(Boolean))

  if (target === '_blank') {
    sanitizedRel.add('noopener')
  }

  /**
   * To avoid exploitation of the window.opener API, Adding noreferrer,
   * as recommended in https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
   */
  sanitizedRel.add('noreferrer')

  return { rel: Array.from(sanitizedRel).join(' '), target }
}

function useSafeLink(
  props: AnchorHTMLAttributes<HTMLAnchorElement>
): AnchorHTMLAttributes<HTMLAnchorElement> {
  const { rel, target } = checkTarget(props) || {}

  return { ...checkHref(props), rel, target }
}

export default useSafeLink
