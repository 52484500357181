import { useEffect, useReducer, useRef, Dispatch } from 'react'

import get from 'utils/toolset/get'
import type { Step } from './Steps.types'

export interface useStepsProps {
  steps: Array<Step>
  current?: number | string
}

type State = { current: number | string; steps: Array<Step> }
type Action =
  | { type: 'navigate'; payload: { stepID: number | string } }
  | { type: 'next' }
  | { type: 'previous' }
  | { type: 'complete'; payload: { stepID: number | string } }
  | { type: 'reset'; payload: State }

function useSteps({ steps, current: currentProp }: useStepsProps): [State, Dispatch<Action>] {
  const stepIndexByID = useRef<Record<number | string, number>>({})

  useEffect(
    function initStepIndexByIDMap() {
      stepIndexByID.current = {}
      ;(steps || []).forEach((step, index) => {
        stepIndexByID.current[step.id] = index
      })
    },
    [steps]
  )

  const [state, dispatch] = useReducer(reducer, init(steps, currentProp))

  function reducer(state: State, action: Action): State {
    const { current } = state

    // TODO: improve using object to map each case as individual functions
    switch (action.type) {
      case 'previous': {
        const currentIndex = getStepIndex(current)

        if (currentIndex == null) {
          return state
        }

        const newCurrentIndex = Math.max(currentIndex - 1, 0)

        return {
          ...state,
          current: steps[newCurrentIndex]?.id,
        }
      }
      case 'next': {
        const currentIndex = getStepIndex(current)

        if (currentIndex == null) {
          return state
        }

        const newCurrentIndex = Math.min(currentIndex + 1, steps.length - 1)

        return {
          ...state,
          current: steps[newCurrentIndex]?.id,
        }
      }
      case 'navigate': {
        const { stepID } = action.payload
        const currentIndex = getStepIndex(current)
        const stepIndex = getStepIndex(stepID)
        const step = get(steps, stepIndex)

        if (!step) {
          return state
        }

        return {
          ...state,
          current: steps[stepIndex]?.id || steps[currentIndex]?.id,
        }
      }
      case 'complete': {
        const { stepID } = action.payload
        const { steps } = state

        const stepIndex = getStepIndex(stepID)
        const step = get(steps, stepIndex)

        if (!step) {
          return state
        }

        return {
          ...state,
          steps: [
            ...steps.slice(0, stepIndex),
            { ...step, complete: true },
            ...steps.slice(stepIndex + 1),
          ],
        }
      }
      case 'reset': {
        const { steps, current } = action.payload

        return {
          ...state,
          ...init(steps, current),
        }
      }
      default:
        return state
    }
  }

  // TODO: gather step info to feed Steps component
  function init(steps: Array<Step>, current?: number | string): State {
    return {
      current: current || (get(steps, '0.id') as number | string),
      steps,
    }
  }

  function getStepIndex(stepID: number | string): number {
    return stepIndexByID.current[stepID]
  }

  // TODO: write helper functions to abstract inner dispatch mechanism
  return [state, dispatch]
}

export default useSteps
