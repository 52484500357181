/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { Dropdown } from 'components/Dropdown'
import { Icon as DefaultIcon } from 'components/Icon'
import { Group as DefaultGroup } from 'components/Layout'
import conditional, { whenProps } from 'tools/conditional'
import { getToken as token } from 'theming'

import { SelectContext } from './Select.context'

import type { SelectableType } from 'hooks/useSelectable'
import type { DropdownMenuItemProps } from 'components/Dropdown'
import type { Option, SelectOptionProps } from './Select.types'

const Group = styled(DefaultGroup).attrs({
  as: 'span',
})``

const Icon = styled(DefaultIcon)`
  color: ${token('color-accent')};
`

export const DefaultSelectOption = styled(Dropdown.Item)<SelectOptionProps>`
  background: ${conditional({
      'color-accent': whenProps({ 'aria-selected': true }),
      'color-neutral-white': whenProps({ 'aria-selected': false }),
    })}${conditional({
      '33': whenProps({ 'aria-selected': true }),
      ff: whenProps({ 'aria-selected': false }),
    })};
`

function SelectOption({
  children,
  value,
  leading,
  trailing,
  ...rest
}: {
  children: string
  value: string
  leading?: DropdownMenuItemProps['leading']
  trailing?: DropdownMenuItemProps['trailing']
}): JSX.Element {
  const select =
    useContext(SelectContext) ||
    (() => {
      throw new Error('Options should be wrapped in a SelectContext')
    })()
  const option: Option = { value, label: children } as Option
  const checked = select.getOption(option).checked || false

  return (
    <DefaultSelectOption
      {...rest}
      {...select.getOptionProps({ option })}
      leading={
        select.selectable.type() === ('multiple' as SelectableType) ? (
          <Group align="center" space="s">
            <Checkbox scale="small" defaultChecked={checked} />
            {leading}
          </Group>
        ) : (
          leading
        )
      }
      trailing={
        select.selectable.type() === ('single' as SelectableType) ? (
          checked ? (
            <Icon name="check" />
          ) : null
        ) : (
          trailing
        )
      }
    >
      {children}
    </DefaultSelectOption>
  )
}

export default SelectOption
