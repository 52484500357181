import {
  generateBorderGetters,
  generateColorGetter,
  generateFontGetters,
  generateShadowGetter,
  generateSpacingGetter,
} from '../../theming/theming.helpers'

import rem from '../../utils/toolset/rem'

const COLORS = {
  'color-primary': '#56748E',
  'color-accent': '#0B6FB6',

  'color-neutral-darkest': '#0A0A0B',
  'color-neutral-darker': '#313336',
  'color-neutral-dark': '#575C61',
  'color-neutral': '#929AA1',
  'color-neutral-light': '#CACCCF',
  'color-neutral-lighter': '#E9EBEC',
  'color-neutral-lightest': '#FCFCFC',
  'color-neutral-white': '#FFFFFF',

  'color-success-dark': '#267E4A',
  'color-success': '#48C881',
  'color-success-light': '#C0ECD3',

  'color-transparent': 'transparent',

  'color-warning-dark': '#955800',
  'color-warning': '#FFCE4F',
  'color-warning-light': '#FFF5D6',

  'color-danger-dark': '#8F002B',
  'color-danger': '#E80045',
  'color-danger-light': '#FFD6E2',
}

export const OPACITIES = {
  'opacity-0': 0,
  'opacity-20': 0.2,
  'opacity-40': 0.4,
  'opacity-60': 0.6,
  'opacity-100': 1,
}

const SPACINGS = {
  'space-none': 0,
  'space-2xs': rem('2px'),
  'space-xs': rem('4px'),
  'space-s': rem('8px'),
  'space-m': rem('16px'),
  'space-l': rem('24px'),
  'space-xl': rem('32px'),
  'space-2xl': rem('40px'),
  'space-3xl': rem('48px'),
}

const FONT_FAMILIES = {
  'font-family-default': 'Manrope, sans-serif',
  'font-family-monospace': 'Roboto Mono, monospace',
}

const FONT_SIZES = {
  'font-size-6': rem('10px'),
  'font-size-5': rem('12px'),
  'font-size-4': rem('14px'),
  'font-size-3': rem('16px'),
  'font-size-2': rem('20px'),
  'font-size-1': rem('26px'),
}

const FONT_WEIGHTS = {
  'font-weight-regular': 400,
  'font-weight-medium': 500,
  'font-weight-bold': 800,
}

const FONT_HEIGHTS = {
  'font-height-1': '100%',
  'font-height-2': '125%',
  'font-height-3': '140%',
}

const SHADOWS = {
  'shadow-none': 'none',
  'shadow-modal': '0px 2px 6px 0px rgba(10, 10, 11, 0.4)',
  'shadow-droplist': '0px 2px 3px 0px rgba(10, 10, 11, 0.4)',
  'shadow-glow-primary': '0px 0px 4px 0px rgba(11, 111, 182, 1.0)',
  'shadow-glow-warning': '0px 0px 4px 0px rgba(255, 206, 79, 1.0)',
}

const BORDER_RADIUSES = {
  'border-radius-none': 0,
  'border-radius-s': rem('4px'),
  'border-radius-m': rem('8px'),
  'border-radius-l': rem('16px'),
  'border-radius-circle': '50%',
}

const BORDER_WIDTHS = {
  'border-width-none': 0,
  'border-width-thin': '1px',
  'border-width-medium': '2px',
  'border-width-bold': '4px',
}

const Z_INDEXES = {
  'z-index-none': 0,
  'z-index-default': 1,
  'z-index-droplist': 10,
  'z-index-tooltip': 15,
  'z-index-overlay': 20,
  'z-index-modal': 30,
}

const color = generateColorGetter(COLORS)
const shadow = generateShadowGetter(SHADOWS)
const spacing = generateSpacingGetter(SPACINGS)
const font = generateFontGetters({
  families: FONT_FAMILIES,
  heights: FONT_HEIGHTS,
  sizes: FONT_SIZES,
  weights: FONT_WEIGHTS,
})
const border = generateBorderGetters({
  radiuses: BORDER_RADIUSES,
  widths: BORDER_WIDTHS,
})

// TODO: how to inherit from other theme?

const alice = {
  ...BORDER_RADIUSES,
  ...BORDER_WIDTHS,
  ...COLORS,
  ...FONT_FAMILIES,
  ...FONT_HEIGHTS,
  ...FONT_SIZES,
  ...FONT_WEIGHTS,
  ...OPACITIES,
  ...SHADOWS,
  ...SPACINGS,
  ...Z_INDEXES,
  // button
  'button-border-radius': border.radius('s'),
  'button-border-width': border.width('thin'),
  'button-font-weight': font.weight('bold'),
  'button-font-height': font.height('1'),
  'button-small-font-size': font.size('5'),
  'button-small-height': rem('24px'),
  'button-small-padding-x': spacing('s'),
  'button-small-padding-y': rem('6px'),
  'button-font-size': font.size('3'),
  'button-height': rem('36px'),
  'button-width': 'auto',
  'button-padding-y': rem('10px'),
  'button-padding-x': spacing('m'),
  'button-spacing-x': spacing('s'),
  'button-large-font-size': font.size('3'),
  'button-large-height': rem('48px'),
  'button-large-padding-x': spacing('m'),
  'button-large-padding-y': rem('14px'),

  // button primary
  'button-primary-background': color('primary'),
  'button-primary-background--hover': color('accent'),
  'button-primary-background--focus': color('primary'),
  'button-primary-background--active': color('accent', 0.6),
  'button-primary-background--disabled': color('primary'),

  'button-primary-border-color': color('primary'),
  'button-primary-border-color--hover': color('accent'),
  'button-primary-border-color--focus': color('accent'),
  'button-primary-border-color--active': color('accent', 0.6),
  'button-primary-border-color--disabled': color('primary'),

  'button-primary-color': color('neutral-white'),
  'button-primary-color--hover': color('neutral-white'),
  'button-primary-color--focus': color('neutral-white'),
  'button-primary-color--active': color('neutral-white'),
  'button-primary-color--disabled': color('neutral-white'),

  'button-primary-outline': shadow('glow-primary'),

  // button secondary
  'button-secondary-background': color('transparent'),
  'button-secondary-background--hover': color('neutral', 0.2),
  'button-secondary-background--focus': color('neutral', 0.2),
  'button-secondary-background--active': color('transparent'),
  'button-secondary-background--disabled': color('transparent'),

  'button-secondary-border-color': color('primary'),
  'button-secondary-border-color--hover': color('accent'),
  'button-secondary-border-color--focus': color('accent'),
  'button-secondary-border-color--active': color('accent', 0.6),
  'button-secondary-border-color--disabled': color('primary'),

  'button-secondary-color': color('primary'),
  'button-secondary-color--hover': color('accent'),
  'button-secondary-color--focus': color('primary'),
  'button-secondary-color--active': color('accent', 0.6),
  'button-secondary-color--disabled': color('primary'),

  'button-secondary-outline': shadow('glow-primary'),

  // button secondary dark
  'button-secondary-dark-background': color('transparent'),
  'button-secondary-dark-background--hover': color('neutral', 0.2),
  'button-secondary-dark-background--focus': color('transparent'),
  'button-secondary-dark-background--active': color('neutral', 0.4),
  'button-secondary-dark-background--disabled': color('transparent'),

  'button-secondary-dark-border-color': color('neutral-light'),
  'button-secondary-dark-border-color--hover': color('accent'),
  'button-secondary-dark-border-color--focus': color('neutral-light'),
  'button-secondary-dark-border-color--active': color('accent', 0.6),
  'button-secondary-dark-border-color--disabled': color('neutral-light'),

  'button-secondary-dark-color': color('neutral-light'),
  'button-secondary-dark-color--hover': color('accent'),
  'button-secondary-dark-color--focus': color('neutral-light'),
  'button-secondary-dark-color--active': color('accent', 0.6),
  'button-secondary-dark-color--disabled': color('neutral-light'),

  // button warning
  'button-warning-background': color('transparent'),
  'button-warning-background--hover': color('warning', 0.2),
  'button-warning-background--focus': color('transparent'),
  'button-warning-background--active': color('transparent'),
  'button-warning-background--disabled': color('transparent'),

  'button-warning-border-color': color('warning-dark'),
  'button-warning-border-color--hover': color('warning'),
  'button-warning-border-color--focus': color('warning-dark'),
  'button-warning-border-color--active': color('warning'),
  'button-warning-border-color--disabled': color('warning-dark'),

  'button-warning-color': color('warning-dark'),
  'button-warning-color--hover': color('warning'),
  'button-warning-color--focus': color('warning-dark'),
  'button-warning-color--active': color('warning'),
  'button-warning-color--disabled': color('warning-dark'),

  'button-warning-outline': shadow('glow-warning'),

  // button icon
  'button-icon-border-radius': border.radius('circle'),
  'button-icon-small-width': rem('24px'),
  'button-icon-width': rem('36px'),
  'button-icon-large-width': rem('48px'),

  'button-icon-background': color('neutral-lighter'),
  'button-icon-background--hover': color('neutral-lighter'),
  'button-icon-background--focus': color('neutral-lighter'),
  'button-icon-background--active': color('neutral-light'),
  'button-icon-background--disabled': color('neutral-lighter'),

  'button-icon-border-color': color('neutral-lighter'),
  'button-icon-border-color--hover': color('neutral-lighter'),
  'button-icon-border-color--focus': color('neutral-lighter'),
  'button-icon-border-color--active': color('neutral-light'),
  'button-icon-border-color--disabled': color('neutral-lighter'),

  'button-icon-color': color('neutral-darkest'),
  'button-icon-color--hover': color('accent'),
  'button-icon-color--focus': color('neutral-darkest'),
  'button-icon-color--active': color('neutral-darkest'),
  'button-icon-color--disabled': color('neutral-darkest'),

  'button-icon-outline': shadow('glow-primary'),

  // tag
  'tag-border-radius': rem('30px'),
  'tag-border-width': border.width('thin'),
  'tag-font-weight': font.weight('bold'),
  'tag-font-height': font.height('1'),
  'tag-spacing': `0 ${spacing('s')}`,
  'tag-spacing-removable': `0 ${spacing('xs')} 0 ${spacing('s')}`,
  'tag-width': 'auto',
  'tag-outline': shadow('glow-primary'),

  'tag-remove-button-background': color('neutral-white'),
  'tag-remove-button-border-radius': rem('24px'),
  'tag-icon-spacing': spacing('xs'),

  // tag small
  'tag-small-font-size': font.size('6'),
  'tag-small-height': rem('16px'),
  'tag-small-transform': 'uppercase',
  'tag-small-leading-display': 'none',
  'tag-small-remove-button-size': rem('0'),
  'tag-small-remove-button-icon-size': rem('0'),
  'tag-small-spacing-removable': `0 ${spacing('s')}`,

  // tag medium
  'tag-font-size': font.size('5'),
  'tag-height': rem('24px'),
  'tag-transform': 'none',
  'tag-leading-display': 'inline-flex',
  'tag-remove-button-size': rem('16px'),
  'tag-remove-button-icon-size': rem('10px'),

  // tag large
  'tag-large-font-size': font.size('4'),
  'tag-large-height': rem('32px'),
  'tag-large-transform': 'none',
  'tag-large-leading-display': 'inline-flex',
  'tag-large-remove-button-size': rem('24px'),
  'tag-large-remove-button-icon-size': rem('12px'),

  // tag default
  'tag-default-background': color('neutral-light'),
  'tag-default-background--hover': color('accent', 0.4),
  'tag-default-background--focus': color('neutral-dark', 0.2),

  'tag-default-color': color('neutral-darkest'),
  'tag-default-color--hover': color('neutral-darkest'),
  'tag-default-color--focus': color('neutral-dark'),

  'tag-default-border-color': 'transparent',
  'tag-default-border-color--hover': 'transparent',
  'tag-default-border-color--focus': 'transparent',

  // tag outline
  'tag-outlined-background': color('transparent'),
  'tag-outlined-background--hover': color('accent', 0.4),
  'tag-outlined-background--focus': color('neutral-white'),

  'tag-outlined-color': color('neutral-darkest'),
  'tag-outlined-color--hover': color('neutral-darkest'),
  'tag-outlined-color--focus': color('neutral-darker'),

  'tag-outlined-border-color': color('neutral-darkest'),
  'tag-outlined-border-color--hover': color('neutral-darkest'),
  'tag-outlined-border-color--focus': color('neutral-darker'),

  // tag accent
  'tag-accent-background': color('accent', 0.2),
  'tag-accent-background--hover': color('accent', 0.4),
  'tag-accent-background--focus': color('accent', 0.2),

  'tag-accent-color': color('accent'),
  'tag-accent-color--hover': color('accent'),
  'tag-accent-color--focus': color('accent'),

  'tag-accent-border-color': 'transparent',
  'tag-accent-border-color--hover': 'transparent',
  'tag-accent-border-color--focus': 'transparent',

  // tag success
  'tag-success-background': color('success-light'),
  'tag-success-background--hover': color('success-light'),
  'tag-success-background--focus': color('success-light'),

  'tag-success-color': color('success-dark'),
  'tag-success-color--hover': color('success-dark'),
  'tag-success-color--focus': color('success-dark'),

  'tag-success-border-color': color('success-light'),
  'tag-success-border-color--hover': color('success-light'),
  'tag-success-border-color--focus': color('success-light'),

  // tag warning
  'tag-warning-background': color('warning-light'),
  'tag-warning-background--hover': color('warning-light'),
  'tag-warning-background--focus': color('warning-light'),

  'tag-warning-color': color('warning-dark'),
  'tag-warning-color--hover': color('warning-dark'),
  'tag-warning-color--focus': color('warning-dark'),

  'tag-warning-border-color': color('warning-light'),
  'tag-warning-border-color--hover': color('warning-light'),
  'tag-warning-border-color--focus': color('warning-light'),

  // tag danger
  'tag-danger-background': color('danger-light'),
  'tag-danger-background--hover': color('danger-light'),
  'tag-danger-background--focus': color('danger-light'),

  'tag-danger-color': color('danger-dark'),
  'tag-danger-color--hover': color('danger-dark'),
  'tag-danger-color--focus': color('danger-dark'),

  'tag-danger-border-color': color('danger-light'),
  'tag-danger-border-color--hover': color('danger-light'),
  'tag-danger-border-color--focus': color('danger-light'),

  // checkbox
  'checkbox-color': color('neutral-darker'),
  'checkbox-font-weight': font.weight('medium'),
  'checkbox-font-size': font.size('4'),

  'checkbox-selector-border-radius': border.radius('s'),
  'checkbox-selector-size': rem('16px'),
  'checkbox-selector-outline': shadow('glow-primary'),

  'checkbox-small-font-size': font.size('5'),

  'checkbox-selector-icon-color': color('neutral-white'),

  'checkbox-selector-background': color('neutral-white'),
  'checkbox-selector-background--hover': color('neutral-white'),
  'checkbox-selector-background--focus': color('neutral-white'),
  'checkbox-selector-background--disabled': color('neutral-light'),

  'checkbox-selector-checked-background': color('primary'),
  'checkbox-selector-checked-background--hover': color('accent'),
  'checkbox-selector-checked-background--focus': color('primary'),
  'checkbox-selector-checked-background--disabled': color('neutral-dark'),

  'checkbox-selector-border-color': color('neutral-darker'),
  'checkbox-selector-border-color--hover': color('accent'),
  'checkbox-selector-border-color--focus': color('accent'),
  'checkbox-selector-border-color--disabled': color('neutral-darker'),

  'checkbox-selector-checked-border-color': color('primary'),
  'checkbox-selector-checked-border-color--hover': color('accent'),
  'checkbox-selector-checked-border-color--focus': color('accent'),
  'checkbox-selector-checked-border-color--disabled': color('neutral-darkest'),

  // checkbox dark
  'checkbox-dark-color': color('neutral-lighter'),

  'checkbox-dark-selector-background': color('neutral-darker'),
  'checkbox-dark-selector-background--hover': color('neutral-darker'),
  'checkbox-dark-selector-background--focus': color('neutral-darker'),
  'checkbox-dark-selector-background--disabled': color('neutral-neutral'),

  'checkbox-dark-selector-border-color': color('neutral-lighter'),
  'checkbox-dark-selector-border-color--hover': color('accent'),
  'checkbox-dark-selector-border-color--focus': color('accent'),
  'checkbox-dark-selector-border-color--disabled': color('neutral-lighter'),

  // radio
  'radio-color': color('accent'),
  'radio-font-weight': font.weight('medium'),
  'radio-font-size': font.size('4'),

  'radio-selector-border-radius': border.radius('circle'),
  'radio-selector-size': rem('24px'),
  'radio-selector-outline': shadow('glow-primary'),

  'radio-small-font-size': font.size('5'),
  'radio-small-selector-size': rem('16px'),

  'radio-selector-background': color('neutral-white'),
  'radio-selector-background--hover': color('neutral-white'),
  'radio-selector-background--focus': color('neutral-white'),
  'radio-selector-background--disabled': color('neutral-light'),

  'radio-selector-border-color': color('neutral-darker'),
  'radio-selector-border-color--hover': color('accent'),
  'radio-selector-border-color--focus': color('accent'),
  'radio-selector-border-color--disabled': color('neutral-darker'),

  'radio-selector-border-color--checked': color('accent'),

  // radio dark
  'radio-dark-color': color('neutral-lighter'),

  'radio-dark-selector-background': color('neutral-darker'),
  'radio-dark-selector-background--hover': color('neutral-darker'),
  'radio-dark-selector-background--focus': color('neutral-darker'),
  'radio-dark-selector-background--disabled': color('neutral-neutral'),

  'radio-dark-selector-border-color': color('neutral-lighter'),
  'radio-dark-selector-border-color--hover': color('accent'),
  'radio-dark-selector-border-color--focus': color('accent'),
  'radio-dark-selector-border-color--disabled': color('neutral-lighter'),

  // tooltip
  'tooltip-arrow-height': rem('12px'),
  'tooltip-arrow-width': rem('20px'),
  'tooltip-background': color('neutral-darker'),
  'tooltip-border-radius': rem('8px'),
  'tooltip-color': color('neutral-white'),
  'tooltip-dark-outline': color('neutral', 0.4),
  'tooltip-font-size': font.size('4'),
  'tooltip-font-height': font.height('3'),
  'tooltip-max-width': rem('240px'),
  'tooltip-min-width': rem('80px'),
  'tooltip-outline': color('neutral-darkest'),
  'tooltip-padding-x': spacing('m'),
  'tooltip-padding-y': spacing('s'),
  'tooltip-margin': spacing('s'),
  'tooltip-shadow': shadow('droplist'),

  // modal
  'modal-small-max-width': rem('344px'),
  'modal-max-width': rem('484px'),
  'modal-large-max-width': rem('688px'),
  'modal-overlay-background': color('neutral-darkest', 0.4),

  // dialog
  'dialog-header-color': color('neutral-darkest'),
  'dialog-header-border-color': color('neutral-light'),
  'dialog-body-font-color': color('color-neutral-darker'),

  // text-field
  'text-field-border-radius': border.radius('s'),
  'text-field-color': color('neutral-darker'),
  'text-field-font-size': font.size('4'),
  'text-field-font-weight': font.weight('medium'),
  'text-field-font-height': font.height('3'),
  'text-field-height': rem('36px'),
  'text-field-padding-x': spacing('s'),
  'text-field-padding-y': spacing('s'),

  'text-field-outline': shadow('glow-primary'),

  'text-field-small-height': rem('24px'),
  'text-field-small-padding-x': spacing('s'),
  'text-field-small-padding-y': spacing('xs'),
  'text-field-small-font-size': font.size('5'),

  'text-field-large-height': rem('45px'),
  'text-field-large-padding-x': spacing('s'),
  'text-field-large-padding-y': rem('12px'),
  'text-field-large-font-size': font.size('4'),

  'text-field-background': color('neutral-white'),
  'text-field-background--hover': color('neutral-white'),
  'text-field-background--focus': color('neutral-white'),
  'text-field-background--disabled': color('neutral-light'),

  'text-field-border-color': color('neutral-darker'),
  'text-field-border-color--hover': color('accent'),
  'text-field-border-color--focus': color('accent'),
  'text-field-border-color--disabled': color('neutral-darker'),

  // text-field dark
  'text-field-dark-color': color('neutral-lighter'),

  'text-field-dark-background': color('neutral-darker'),
  'text-field-dark-border-color': color('neutral-lighter'),
  'text-field-dark-border-color--hover': color('accent'),
  'text-field-dark-border-color--focus': color('accent'),
  'text-field-dark-border-color--disabled': color('neutral-lighter'),

  'text-field-success-border-color': color('accent'),
  'text-field-danger-border-color': color('danger'),

  'text-field-dark-outline': color('neutral', 0.4),

  // textarea
  'textarea-border-radius': border.radius('s'),
  'textarea-color': color('neutral-darker'),
  'textarea-font-size': font.size('4'),
  'textarea-font-weight': font.weight('medium'),
  'textarea-font-height': font.height('3'),
  'textarea-min-height': rem('64px'),
  'textarea-padding-x': spacing('s'),
  'textarea-padding-y': spacing('s'),

  'textarea-outline': shadow('glow-primary'),

  'textarea-small-padding-x': spacing('s'),
  'textarea-small-padding-y': spacing('xs'),
  'textarea-small-font-size': font.size('5'),

  'textarea-large-height': rem('45px'),
  'textarea-large-padding-x': spacing('s'),
  'textarea-large-padding-y': rem('12px'),
  'textarea-large-font-size': font.size('4'),

  'textarea-background': color('neutral-white'),
  'textarea-background--hover': color('neutral-white'),
  'textarea-background--focus': color('neutral-white'),
  'textarea-background--disabled': color('neutral-light'),

  'textarea-border-color': color('neutral-darker'),
  'textarea-border-color--hover': color('accent'),
  'textarea-border-color--focus': color('accent'),
  'textarea-border-color--disabled': color('neutral-darker'),

  // textarea dark
  'textarea-dark-color': color('neutral-lighter'),

  'textarea-dark-background': color('neutral-darker'),
  'textarea-dark-border-color': color('neutral-lighter'),
  'textarea-dark-border-color--hover': color('accent'),
  'textarea-dark-border-color--focus': color('accent'),
  'textarea-dark-border-color--disabled': color('neutral-lighter'),

  'textarea-success-border-color': color('accent'),
  'textarea-danger-border-color': color('danger'),

  'textarea-dark-outline': color('neutral', 0.4),

  // link
  'link-font-size': font.size('3'),
  'link-font-height': font.height('2'),
  'link-color': color('accent'),

  'link-font-weight': font.weight('medium'),
  'link-font-weight--hover': font.weight('bold'),

  // breadcrumbs
  'breadcrumbs-font-size': font.size('2'),
  'breadcrumbs-font-height': font.height('2'),
  'breadcrumbs-font-weight': font.weight('medium'),
  'breadcrumbs-font-weight--active': font.weight('bold'),
  'breadcrumbs-spacing-x': rem('36px'),

  //banner
  'banner-font-size': font.size('4'),
  'banner-font-height': font.height('3'),
  'banner-default-height': rem('40px'),
  'banner-min-width': rem('320px'),
  'banner-description-color': color('neutral-darker'),
  'banner-title-font-weight': font.weight('bold'),
  'banner-border-radius': border.radius('m'),
  'banner-border-width': border.width('thin'),
  'banner-close-button-color': color('neutral-darker'),
  'banner-icon-width': rem('24px'),
  'banner-icon-height': rem('24px'),
  'banner-icon-margin-right': rem('10px'),
  'banner-default-padding-x': spacing('m'),
  'banner-default-padding-y': spacing('s'),
  'banner-large-padding': spacing('m'),
  'banner-default-icon-alignment': 'center',
  'banner-large-icon-alignment': 'start',
  'banner-default-close-top': spacing('s'),
  'banner-default-close-right': spacing('s'),
  'banner-large-close-top': rem('12px'),
  'banner-large-close-right': rem('12px'),

  //banner success
  'banner-title-color-success': color('success-dark'),
  'banner-border-color-success': color('success'),
  'banner-background-success': color('success-light'),

  //banner danger
  'banner-title-color-danger': color('danger-dark'),
  'banner-border-color-danger': color('danger'),
  'banner-background-danger': color('danger-light'),

  //banner warning
  'banner-title-color-warning': color('warning-dark'),
  'banner-border-color-warning': color('warning'),
  'banner-background-warning': color('warning-light'),

  //banner neutral
  'banner-title-color-neutral': color('neutral-darker'),
  'banner-border-color-neutral': color('neutral'),
  'banner-background-neutral': color('neutral-light'),

  //banner action
  'banner-button-background-success': color('success-dark'),
  'banner-button-background-neutral': color('neutral-dark'),
  'banner-button-background-danger': color('danger-dark'),
  'banner-button-background-warning': color('warning-dark'),
  'banner-button-background-secondary': color('transparent'),
  'banner-button-text-color': color('neutral-white'),
  'banner-secondary-button-margin-left': spacing('s'),

  // progressbar
  'progressbar-height': rem('5px'),
  'progressbar-background': color('transparent'),
  'progressbar-line': color('darker'),
  // neutral
  'progressbar-neutral-fill': color('neutral-light'),
  // success
  'progressbar-success-fill': color('success-light'),
  // danger
  'progressbar-danger-fill': color('danger-light'),
  // warning
  'progressbar-warning-fill': color('warning-light'),

  // toast
  'toast-padding-x': spacing('m'),
  'toast-padding-y': spacing('m'),
  'toast-border-radius': border.radius('s'),
  'toast-font-color': color('neutral-white'),
  'toast-font-size': font.size('4'),
  'toast-font-weight': font.weight('bold'),
  'toast-font-height': font.height('3'),
  'toast-close-x': spacing('xs'),
  'toast-close-y': spacing('xs'),
  'toast-shadow': shadow('modal'),
  'toast-width': rem('320px'),
  // neutral
  'toast-neutral-background': color('neutral-dark'),
  // success
  'toast-success-background': color('success'),
  // danger
  'toast-danger-background': color('danger'),
  // warning
  'toast-warning-background': color('warning'),

  // Label
  'label-font-size': font.size('4'),
  'label-font-weight': font.weight('medium'),
  'label-font-height': font.height('3'),
  'label-font-color': color('darker'),

  // Label required
  'label-required-color': color('danger'),

  // Label tooltip
  'label-tooltip-margin-left': spacing('xs'),

  'label-tooltip-font-weight': font.weight('bold'),
  'label-tooltip-font-size': font.size('5'),
  'label-tooltip-font-color': color('darker'),

  'label-tooltip-background-color': color('neutral'),

  // Label dark
  'label-dark-font-color': color('neutral-white'),

  // Label tooltip dark
  'label-tooltip-dark-font-color': color('neutral-white'),

  'label-tooltip-dark-background-color': color('neutral'),

  // Switch
  'switch-width': rem('52px'),
  'switch-height': rem('22px'),
  'switch-border-radius': rem('22px'),

  'switch-outline': shadow('glow-primary'),

  // Switch Large
  'switch-large-width': rem('70px'),
  'switch-large-height': rem('32px'),
  'switch-large-border-radius': rem('32px'),

  // Switch Icon
  'switch-icon-height': rem('16px'),
  'switch-icon-width': rem('16px'),
  'switch-icon-color': color('neutral-white'),

  // Switch Icon Large
  'switch-large-icon-height': rem('20px'),
  'switch-large-icon-width': rem('20px'),

  // Switch Icon Active
  'switch-icon-active-x': rem('8px'),
  'switch-icon-active-y': rem('3px'),

  // Switch Large Icon Active
  'switch-large-icon-active-x': rem('8px'),
  'switch-large-icon-active-y': rem('6px'),

  // Switch Icon Inactive
  'switch-icon-inactive-x': rem('28px'),
  'switch-icon-inactive-y': rem('3px'),

  // Switch Large Icon Inactive
  'switch-large-icon-inactive-x': rem('42px'),
  'switch-large-icon-inactive-y': rem('6px'),

  // Switch Inactive
  'switch-inactive-background-color': color('neutral'),
  'switch-inactive-background-color--hover': color('neutral-dark', 0.4),

  // Switch Active
  'switch-active-background-color': color('accent'),
  'switch-active-background-color--hover': color('accent', 0.6),

  // Switch Slider
  'switch-slider-x': rem('3px'),
  'switch-slider-active-x': rem('33px'),
  'switch-slider-y': rem('3px'),
  'switch-slider-active-y': rem('3px'),
  'switch-slider-size': rem('16px'),
  'switch-slider-background-color': color('neutral-white'),
  'switch-slider-border-radius': border.radius('circle'),

  // Switch Slider Large
  'switch-slider-large-x': rem('2px'),
  'switch-slider-large-active-x': rem('40px'),
  'switch-slider-large-y': rem('2px'),
  'switch-slider-large-active-y': rem('2px'),
  'switch-slider-large-size': rem('28px'),

  // steps
  'steps-step-padding-y': spacing('xl'),
  'steps-step-padding-x': spacing('xl'),
  'steps-color': color('neutral-darker'),
  'steps-progress-padding-y': spacing('xl'),
  'steps-progress-padding-x': spacing('xl'),
  'steps-spacing-y': spacing('3xl'),
  'steps-indicator-size': rem('24px'),
  'steps-conector-height': '1px',
  'steps-conector-height--complete': '3px',
  'steps-conector-top': '12px', // Math.round((steps-indicator-size - steps-conector-height) / 2)
  'steps-conector-top--complete': '11px', // Math.round((steps-indicator-size - steps-conector-height--complete) / 2)

  // card
  'card-background': color('neutral-white'),
  'card-border-color': color('neutral-light'),
  'card-border-radius': border.radius('s'),
  'card-color': color('neutral-darker'),
  'card-flag-background-danger': color('danger'),
  'card-flag-background-neutral': color('neutral-light'),
  'card-flag-background-success': color('success'),
  'card-flag-background-warning': color('warning'),
  'card-flag-width': rem('4px'),
  'card-font-height': font.height('3'),
  'card-padding-x': spacing('m'),
  'card-padding-y': spacing('m'),
  'card-separator-background': color('neutral-lighter'),
  'card-subtitle-font-size': font.size('4'),
  'card-subtitle-font-weight': font.weight('medium'),
  'card-title-font-height': font.height('2'),
  'card-title-font-size': font.size('3'),
  'card-title-font-weight': font.weight('bold'),

  // popover
  'popover-background': color('neutral-white'),
  'popover-border-color': color('neutral-light'),
  'popover-border-radius': border.radius('s'),
  'popover-shadow': shadow('droplist'),

  // Side Navigation
  'side-navigation-collapsing-breakpoint': rem('1280px'),
  'side-navigation-width': rem('200px'),
  'side-navigation-width-large': rem('300px'),
  'side-navigation-padding-bottom': spacing('l'),
  'side-navigation-background-color': color('neutral-darker'),
  'side-navigation-component-margin-top': spacing('l'),
  'side-navigation-logo-padding-x': spacing('l'),
  'side-navigation-separator-color': color('neutral-dark'),

  // Table
  'table-row-default-color': color('neutral-white'),
  'table-row-variant-color': color('neutral-light', 0.2),
  'table-row-selected-color': color('accent', 0.2),

  // Top Navigation
  'top-navigation-height': rem('60px'),
  'top-navigation-background-color': color('neutral-white'),
  'top-navigation-border-color': color('neutral-lighter'),
  'top-navigation-dropdown-separator-color': color('neutral-lighter'),
  'top-navigation-dropdown-trigger-color': color('neutral'),
  'top-navigation-dropdown-subitem-color': color('neutral-dark'),
  'top-navigation-item-icon-color': color('neutral-dark'),
  'top-navigation-item-color': color('neutral-darkest'),
  'top-navigation-item-color--hover': color('primary'),
}

export default alice
