import React, { PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'

import { check, getPanelRadioID } from './Steps.helpers'
import { getToken as token } from 'theming'
import StepsContext from './StepsContext'
import StepsProgress from './ProgressSteps'
import StepsStep from './StepsStep'

import type ColorScheme from 'utils/types/ColorScheme'
import type { Step } from './Steps.types'
export interface StepsProps {
  current: number | string
  id: number | string
  steps: Array<Step>
  className?: string
  scheme?: ColorScheme
}

const StepsWrapper = styled.section`
  display: block;
  width: 100%;

  color: ${token('steps-color')};
`

// TODO: improve by extracting steps props right from children.
function Steps({
  id,
  children,
  current,
  steps,
  ...others
}: PropsWithChildren<StepsProps>): JSX.Element {
  useEffect(
    function showCurrentStep() {
      check(getPanelRadioID(current))
    },
    [current]
  )

  return (
    <StepsWrapper id={String(id)} {...others}>
      <StepsProgress current={current} steps={steps} />
      <StepsContext.Provider value={id}>{children}</StepsContext.Provider>
    </StepsWrapper>
  )
}

Steps.defaultProps = {
  scheme: 'light',
}

Steps.Step = StepsStep

export default Steps
