import React from 'react'
import styled from 'styled-components'

import { Spacing } from './Layout.types'
import { getSpace } from './Layout.utils'

import type { HTMLAttributes } from 'react'

export interface StackProps extends HTMLAttributes<HTMLElement> {
  space?: Spacing
}

export const StackContainer = styled.div<{
  $space: StackProps['space']
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: ${getSpace('$space')};
  }
`

/**
 * Stack layout component.
 * @param {StackProps} props
 */
export function Stack(props: StackProps): JSX.Element {
  const { space = 'm', children, ...rest } = props

  return (
    <StackContainer $space={space} {...rest}>
      {children}
    </StackContainer>
  )
}
