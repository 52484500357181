import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'

import type { HTMLAttributes } from 'react'

export type EmptyProps = HTMLAttributes<HTMLDivElement>

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  box-sizing: border-box;
  padding: ${token('space-s')};

  color: ${token('color-neutral')};

  font-size: 1em;
  line-height: ${token('font-height-3')};

  background: ${token('color-transparent')};
`

function Empty({ children, ...others }: EmptyProps): JSX.Element {
  return <Wrapper {...others}>{children}</Wrapper>
}

export default Empty
