import React, { useContext } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { isFunction } from '@loadsmart/utils-function'

import { BaseButton, SelectorButton } from 'components/Button'
import { getToken as token } from 'theming'
import { Icon as DefaultIcon } from 'components/Icon'
import { useFocusWithin } from 'hooks/useFocusWithin'
import activatable from 'styles/hoverable'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import DropdownContext from './Dropdown.context'
import focusable from 'styles/focusable'
import font from 'styles/font'
import hoverable from 'styles/hoverable'
import transition from 'styles/transition'

import type { ButtonHTMLAttributes, HTMLAttributes, MouseEvent } from 'react'
import type { DropdownTriggerProps, DropdownContextReturn } from './Dropdown.types'
import type { IconProps } from 'components/Icon'
import type ColorScheme from 'utils/types/ColorScheme'

/**
 * TODO: throw an error if context is not available
 */
const DropdownTriggerWrapper = styled.div<{ disabled: boolean; scheme: ColorScheme }>`
  ${transition()}

  ${font({
    height: 'font-height-3',
    weight: 'font-weight-medium',
  })}

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-radius: ${token('button-border-radius')};
  border-width: ${token('button-border-width')};
  border-style: solid;
  border-color: ${conditional({
    'button-secondary-border-color': whenProps({ scheme: 'light' }),
    'button-secondary-dark-border-color': whenProps({ scheme: 'dark' }),
  })};

  font-size: ${token('font-size-4')};

  color: ${token('color-neutral-darker')};

  height: 36px;
  flex: 1;

  ${hoverable`
    border-color: ${conditional({
      'button-secondary-border-color--hover': whenProps({ scheme: 'light' }),
      'button-secondary-dark-border-color--hover': whenProps({
        scheme: 'dark',
      }),
    })};
  `}

  ${disableable`
    border-color: ${conditional({
      'button-secondary-border-color--disabled': whenProps({ scheme: 'light' }),
      'button-secondary-dark-border-color--disabled': whenProps({
        scheme: 'dark',
      }),
    })};
  `}

  ${focusable`
    border-color: ${conditional({
      'button-primary-border-color--focus': whenProps({ variant: 'primary' }),
      'button-secondary-border-color--focus': whenProps({ scheme: 'light' }),
      'button-secondary-dark-border-color--focus': whenProps({
        scheme: 'dark',
      }),
      'button-warning-border-color--focus': whenProps({ variant: 'warning' }),
      'button-icon-border-color--focus': whenProps({ variant: 'icon' }),
    })};

    box-shadow: ${token('shadow-glow-primary')};
  `}

  ${activatable`
    border-color: ${conditional({
      'button-secondary-border-color--active': whenProps({ scheme: 'light' }),
      'button-secondary-dark-border-color--active': whenProps({
        scheme: 'dark',
      }),
    })};
  `}
`

const TriggerButton = styled(SelectorButton)`
  flex: 1;

  justify-content: flex-start;

  text-transform: uppercase;

  border: none;
  border-radius: ${token('border-radius-s')} 0 0 ${token('border-radius-s')};
`

const TriggerHandle = styled(BaseButton)`
  ${focusable()}

  background: ${token('color-transparent')};
  border-radius: 0 ${token('border-radius-s')} ${token('border-radius-s')} 0;
  border: none;

  height: calc(100% - 2px);
`

const RotatableIcon = styled(DefaultIcon)<{ $rotate: boolean }>`
  ${conditional({
    'transform: rotate(180deg);': whenProps({ $rotate: true }),
  })}
`

interface DropdownTriggerButtonProps extends DropdownTriggerProps {
  children: DropdownTriggerProps['children'] | ((args: DropdownContextReturn) => React.ReactNode)
}

function Caret(props: Omit<IconProps, 'name'> & { $rotate: boolean }) {
  return <RotatableIcon size={20} {...props} name="chevron-down" />
}

function DropdownTriggerButton(props: DropdownTriggerButtonProps): JSX.Element {
  const { toggle, disabled, expanded } = useContext(DropdownContext)
  const { children, onClick, ...others } = props

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.persist()

    toggle()
    onClick?.(e)
  }

  function renderChildren() {
    if (isFunction(children)) {
      return children({ toggle, disabled, expanded })
    }

    return children
  }

  // TODO: add aria-labelledby that should refer to the button and the dropdown label
  return (
    <TriggerButton
      trailing={<Caret $rotate={expanded} />}
      onClick={handleClick}
      {...others}
      type="button"
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-disabled={disabled}
      disabled={disabled}
    >
      {renderChildren()}
    </TriggerButton>
  )
}

function DropdownTriggerHandle(props: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  const { toggle, expanded, disabled } = useContext(DropdownContext)
  const { onClick, ...others } = props

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.persist()

    toggle()
    onClick?.(e)
  }

  return (
    <TriggerHandle
      onClick={handleClick}
      data-testid="dropdown-trigger-handle"
      {...others}
      type="button"
      tabIndex={-1}
      disabled={disabled}
    >
      <Caret $rotate={expanded} />
    </TriggerHandle>
  )
}

export interface GenericDropdownTriggerProps extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean
  scheme?: ColorScheme
}

export function GenericDropdownTrigger(props: GenericDropdownTriggerProps): JSX.Element {
  const context = useContext(DropdownContext)
  const { ref, focused } = useFocusWithin<HTMLDivElement>()

  if (!context) {
    throw new Error('DropdownTrigger must be inside a DropdownContext')
  }

  const { children, className, scheme = 'light', ...others } = props
  const { disabled } = context

  return (
    <DropdownTriggerWrapper
      role="presentation"
      {...others}
      ref={ref}
      className={clsx(
        {
          'is-disabled': disabled,
          'is-focused': focused,
        },
        className
      )}
      disabled={disabled}
      scheme={scheme}
    >
      {children}
    </DropdownTriggerWrapper>
  )
}

function DropdownTrigger(props: DropdownTriggerProps): JSX.Element {
  const { children, className, ...others } = props

  return (
    <GenericDropdownTrigger className={className}>
      <DropdownTriggerButton {...others}>{children}</DropdownTriggerButton>
    </GenericDropdownTrigger>
  )
}

DropdownTrigger.Button = DropdownTriggerButton
DropdownTrigger.Handle = DropdownTriggerHandle

export default DropdownTrigger
