import React, { Children, Fragment, isValidElement, useEffect } from 'react'
import styled, { css } from 'styled-components'
import type { ReactNode } from 'react'

import { getToken as token } from 'theming'
import { conditional, whenProps, prop } from 'tools/index'
import hoverable from 'styles/hoverable'
import focusable from 'styles/focusable'
import { Checkbox } from 'components/Checkbox'
import { Radio } from 'components/Radio'
import { Link } from 'components/Link'
import { Text } from 'components/Text'

import {
  isCellSelected,
  TableSelectionProvider,
  useIsCellSelected,
  useSelection,
  useTableSelection,
} from './Selection'
import type {
  TableProps,
  TableSectionProps,
  TableRowProps,
  TableCellProps,
  TableCaptionProps,
  TableSelectionProps,
  SelectionCellProps,
} from './Table.types'

const StyledTableBody = styled.tbody`
  /* placeholder */
`

const StyledTableFoot = styled.tfoot`
  box-shadow: 0 -1px 0 ${token('color-neutral')};
`

const StyledTableCaption = styled.caption<{ $position?: 'top' | 'bottom' }>`
  ${conditional({
    ['border-bottom']: whenProps([{ $position: ['top', undefined] }]),
    ['border-top']: whenProps({ $position: 'bottom' }),
  })}: solid 1px ${token('color-neutral-lighter')};

  ${conditional({
    ['margin-bottom']: whenProps([{ $position: ['top', undefined] }]),
    ['margin-top']: whenProps({ $position: 'bottom' }),
  })}: ${token('space-m')};
  padding: ${token('space-m')} ${token('space-s')};

  text-align: left;

  caption-side: ${prop('$position', 'top')};
  background-color: ${token('color-neutral-white')};
`

const StyledCell = css<{ alignment?: string }>`
  color: ${token('color-neutral-darker')};
  font-size: ${token('font-size-4')};
  text-align: ${prop('alignment', 'left')};
  vertical-align: middle;
`

const StyledTableCell = styled.td<{ alignment?: string; format?: string }>`
  ${StyledCell}

  font-family: ${conditional({
    'font-family-monospace': whenProps({ format: ['number', 'currency'] }),
    'font-family-default': whenProps({ format: 'default' }),
  })};
`

const StyledTableHeadCell = styled.th<{ alignment?: string }>`
  ${StyledCell}

  font-weight: ${token('font-weight-bold')};

  padding: ${token('space-s')};

  text-transform: uppercase;
`

const StyledTableHead = styled.thead`
  box-shadow: 0 1px 0 ${token('color-neutral')};

  ${StyledTableCell} {
    padding: ${token('space-s')};

    font-weight: ${token('font-weight-bold')};
    text-transform: uppercase;
  }
`

const StyledTableRow = styled.tr<{ selected?: boolean }>`
  ${StyledTableHead} > & {
    height: 36px;

    background-color: ${token('color-neutral-white')};
  }

  background-color: ${conditional({
    'table-row-selected-color': whenProps({ selected: true }),
    'color-transparent': whenProps({ selected: false }),
  })};

  ${StyledTableBody} > & {
    ${hoverable`
      outline: 1px solid ${token('color-accent')};
    `}

    ${focusable`
      box-shadow: inset ${token('shadow-glow-primary')};
    `}

    &:nth-child(odd) ${StyledTableCell} {
      background: ${token('table-row-variant-color')};
    }

    &:nth-child(even) ${StyledTableCell} {
      background: ${token('color-transparent')};
    }
  }
`

const StyledTable = styled.table<{ scale?: string }>`
  width: 100%;

  white-space: nowrap;

  background-color: ${token('color-neutral-white')};

  border-collapse: collapse;

  ${StyledTableBody} ${StyledTableRow} {
    height: ${conditional({
      '24px': whenProps({ scale: 'small' }),
      '48px': whenProps({ scale: 'default' }),
      '80px': whenProps({ scale: 'large' }),
    })};

    ${StyledTableCell} {
      padding: ${conditional({
        'space-xs': whenProps({ scale: 'small' }),
        'space-s': whenProps({ scale: ['default', 'large'] }),
      })};
    }
  }
`

function Table<T>({
  children,
  selection,
  scale = 'default',
  ...others
}: TableProps<T>): JSX.Element {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TableSelectionProvider selection={selection}>
      <StyledTable scale={scale} {...others}>
        {children}
      </StyledTable>
    </TableSelectionProvider>
  )
}

const TableCaptionInner = styled.div<{ $height?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${prop('$height', 36)}px;
`

function TableCaption({ children, position, height }: TableCaptionProps): JSX.Element {
  return (
    <StyledTableCaption $position={position}>
      <TableCaptionInner $height={height}>{children}</TableCaptionInner>
    </StyledTableCaption>
  )
}

function TableHead({ children, ...others }: TableSectionProps): JSX.Element {
  return <StyledTableHead {...others}>{children}</StyledTableHead>
}

function TableBody({ children, ...others }: TableSectionProps): JSX.Element {
  return <StyledTableBody {...others}>{children}</StyledTableBody>
}

function TableCell({
  children,
  alignment = 'left',
  format = 'default',
  ...others
}: TableCellProps): JSX.Element {
  return (
    <StyledTableCell alignment={alignment} format={format} {...others}>
      {children}
    </StyledTableCell>
  )
}

function SelectionCell<T>({ value, ...props }: SelectionCellProps<T>): JSX.Element {
  const { toggle, register, config } = useTableSelection()

  const selected = useIsCellSelected(value)

  const action = () => toggle(value as T)

  useEffect(() => {
    register(value)
  }, [value, register])

  if (props.children) {
    return (
      <TableCell>
        {props.children({
          selected,
          toggle: action,
        })}
      </TableCell>
    )
  }

  const Input = config.multiple ? Checkbox : Radio

  return (
    <TableCell {...props}>
      {value ? <Input checked={selected} scale="small" onChange={action} /> : null}
    </TableCell>
  )
}

function SelectionHeadCell<T>(props: SelectionCellProps<T>): JSX.Element {
  const { allRowsAreSelected, toggleAll, config } = useTableSelection()

  const selected = allRowsAreSelected
  const action = () => toggleAll()

  if (props.children) {
    return <TableHeadCell {...props}>{props.children({ selected, toggle: action })}</TableHeadCell>
  }

  const Input = config.multiple ? Checkbox : Fragment

  return (
    <TableHeadCell {...props}>
      <Input checked={selected} scale="small" onChange={action} />
    </TableHeadCell>
  )
}

function TableRow({ children, ...others }: TableRowProps): JSX.Element {
  const selected = useIsRowSelected(children)

  return (
    <StyledTableRow {...others} selected={selected}>
      {children}
    </StyledTableRow>
  )
}

function TableHeadCell({ children, alignment = 'left', ...others }: TableCellProps): JSX.Element {
  return (
    <StyledTableHeadCell alignment={alignment} {...others}>
      {children}
    </StyledTableHeadCell>
  )
}

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${token('space-s')};
  margin-left: auto;
`

function TableSelectionActions({ buttons, children, ...others }: TableSelectionProps): JSX.Element {
  const { values, clear } = useSelection()

  return (
    <ActionsWrapper {...others}>
      {values.length > 0 ? (
        <>
          {buttons.map((Action, i) => (
            <Action key={i} values={values} />
          ))}
          <Link
            onClick={(e) => {
              e.preventDefault()
              clear()
            }}
          >
            <Text variant="link">CANCEL</Text>
          </Link>
        </>
      ) : (
        children
      )}
    </ActionsWrapper>
  )
}

function useIsRowSelected(children: ReactNode): boolean {
  const { selected, config } = useTableSelection()

  if (!selected) return false

  const arrChildren = Children.toArray(children)

  const selectionCellChild = arrChildren.find((child) => {
    return isValidElement(child) && child.type === SelectionCell
  })

  if (!selectionCellChild) return false

  const { value } = (selectionCellChild as React.ReactElement).props as SelectionCellProps

  if (!value) return false

  return isCellSelected(value, selected, config)
}

Table.Head = TableHead
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
Table.HeadCell = TableHeadCell
Table.Foot = StyledTableFoot
Table.Caption = TableCaption
Table.Selection = {
  Actions: TableSelectionActions,
  Cell: SelectionCell,
  HeadCell: SelectionHeadCell,
}

export { useSelection }

export default Table
