/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericAdapter } from './Select.constants'
import isEmpty from 'utils/toolset/isEmpty'

import type { SelectAdapter, SelectValue, Option } from './Select.types'

function getAdapter(adapters: Record<string, SelectAdapter<any>>, type?: string) {
  if (type == null) {
    return GenericAdapter
  }

  return adapters[type] || GenericAdapter
}

export function getValue(selection: SelectValue, multiple?: boolean): Option | Option[] | null {
  const pairs = Array.from(selection)

  if (isEmpty(pairs)) {
    return null
  }

  if (multiple) {
    const options = []
    for (const [, option] of pairs) {
      options.push(option)
    }

    return options
  } else {
    const [[, option]] = pairs
    return option
  }
}

export function getDisplayValue(
  adapters: Record<string, SelectAdapter<any>>,
  selection: SelectValue,
  multiple?: boolean
): string {
  if (selection.size == 0) {
    return ''
  }

  if (multiple) {
    return ''
  } else {
    const value = getValue(selection, multiple)
    const adapter = getAdapter(adapters, (value as Option)._type)
    return adapter.getLabel(value)
  }
}

// Based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegExp(value: string): string {
  return String(value || '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
