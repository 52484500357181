import { useCallback, useEffect, useState } from 'react'

import type { HTMLAttributes } from 'react'

export interface DropdownProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onBlur'> {
  disabled?: boolean
  onBlur?: () => void
}

export interface useDropdownProps {
  expanded: boolean
  toggle: () => void
}

export interface GenericDropdownProps extends DropdownProps, useDropdownProps {}

function useDropdown({
  disabled,
}: DropdownProps): {
  expanded: boolean
  toggle: () => void
  expand: () => void
  collapse: () => void
} {
  const [expanded, setExpanded] = useState(false)

  const toggle = useCallback(
    function toggle() {
      if (disabled) {
        return
      }

      setExpanded((isExpanded) => !isExpanded)
    },
    [disabled]
  )

  const expand = useCallback(
    function toggle() {
      if (disabled) {
        return
      }

      setExpanded(true)
    },
    [disabled]
  )

  const collapse = useCallback(
    function toggle() {
      if (disabled) {
        return
      }

      setExpanded(false)
    },
    [disabled]
  )

  useEffect(
    function closeWhenDisabled() {
      if (disabled && expanded) {
        setExpanded(false)
      }
    },
    [disabled, expanded]
  )

  return { expanded, toggle, expand, collapse }
}

export default useDropdown
