import React from 'react'

/**
 * A function that wraps a match into `mark` element to be highlighted.
 * @example
 *
 * @param {string} - label to be split
 * @param {string} - match to be found
 * @returns {ReactNode} - an element with marked match
 */
export default function highlightMatch(str: string, match: string): JSX.Element {
  const index = str.toLowerCase().indexOf(match.toLowerCase())

  if (index === -1) return <>{str}</>

  const pre = str.slice(0, index)
  const mid = str.slice(index, index + match.length)
  const post = str.slice(index + match.length)

  return (
    <>
      {pre}
      <mark>{mid}</mark>
      {post}
    </>
  )
}
