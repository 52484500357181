import React, { forwardRef, useContext } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import { Popover as DefaultPopover } from 'components/Popover'
import disableable from 'styles/disableable'
import DropdownContext from './Dropdown.context'
import focusable from 'styles/focusable'
import font from 'styles/font'
import hoverable from 'styles/hoverable'
import transition from 'styles/transition'
import useID from 'hooks/useID'

import type {
  DropdownMenuItemProps,
  DropdownMenuProps,
  DropdownMenuSectionProps,
} from './Dropdown.types'
import conditional, { whenProps } from 'tools/conditional'

/**
 * TODO: add aria-labelledby that should refer to the dropdown label to the role="menu" container.
 * TODO: navigate through items using arrow keys.
 * TODO: add animation for a smooth open/close effect.
 */

const Popover = styled(DefaultPopover)<{ $align: 'start' | 'end' }>`
  position: absolute;
  top: calc(36px + ${token('space-xs')});

  ${conditional({
    'left: 0;': whenProps({ $align: 'start' }),
    'right: 0;': whenProps({ $align: 'end' }),
  })}

  z-index: ${token('z-index-droplist')};

  min-width: 10em;
`

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  font-size: 1em;
`

const Leading = styled(StyledSpan)`
  /* placeholder */
`

const Trailing = styled(StyledSpan)`
  /* placeholder */
`

const Children = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: ${token('space-s')} 0;

  white-space: nowrap;
`

const DropdownItemWrapper = styled.button`
  ${transition()}

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  ${font({
    height: 'font-height-3',
    weight: 'font-weight-medium',
  })}
  font-size: ${token('font-size-4')};

  height: 36px;
  flex: 1;

  border: none;
  border-radius: ${token('border-radius-s')};
  background: ${token('color-neutral-white')};

  color: ${token('color-neutral-darker')};

  padding: 0 ${token('space-s')};

  margin: 0 ${token('space-s')};

  cursor: pointer;

  ${hoverable`
    background: ${token('color-neutral-lighter')};
  `}

  ${focusable`
    background: ${token('color-neutral-lighter')};
  `}

  ${disableable()}

  ${Children} {
    flex: 1 0 auto;

    text-align: left;
  }

  ${Leading}, ${Trailing} {
    flex: 0 1 auto;
  }

  ${Leading} + ${Children} {
    margin: 0 0 0 ${token('space-s')};
  }

  ${Children} + ${Trailing} {
    margin: 0 0 0 ${token('space-s')};
  }
`

const DropdownSectionHeader = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${font({
    height: 'font-height-3',
    weight: 'font-weight-bold',
  })}

  font-size: ${token('font-size-4')};

  margin: 0 ${token('space-s')};
`

const DropdownSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const GenericDropdownMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${token('space-s')} 0;

  ${font({
    height: 'font-height-3',
    weight: 'font-weight-medium',
  })}

  font-size: ${token('font-size-4')};
`

const DropdownMenuWrapper = styled(GenericDropdownMenuWrapper)`
  max-height: 240px;
  overflow-y: auto;

  ${DropdownItemWrapper} + ${DropdownItemWrapper},
  ${DropdownSectionHeader} + ${DropdownItemWrapper} {
    margin-top: ${token('space-2xs')};
  }

  ${DropdownSectionWrapper} + ${DropdownSectionWrapper} {
    margin: ${token('space-m')} 0 0 0;
  }

  ${GenericDropdownMenuWrapper} + &,
  & + ${GenericDropdownMenuWrapper},
  ${GenericDropdownMenuWrapper} + ${GenericDropdownMenuWrapper} {
    border-top: 2px solid ${token('color-neutral-lighter')};
  }
`

export const DropdownSeparator = styled.hr.attrs({
  'aria-hidden': true,
})`
  display: block;
  height: 2px;

  margin: ${token('space-s')} 0;

  border: 0;
  border-bottom: 2px solid ${token('card-separator-background')};
`

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(function DropdownMenu(
  props: DropdownMenuProps,
  ref
): JSX.Element | null {
  const context = useContext(DropdownContext)

  if (!context) {
    throw new Error('DropdownMenu must be inside a DropdownContext')
  }

  const { children, header, footer, align = 'start', ...others } = props
  const { expanded } = context

  if (!expanded) {
    return null
  }

  return (
    <Popover role="presentation" $align={align}>
      {header && <GenericDropdownMenuWrapper>{header}</GenericDropdownMenuWrapper>}
      {children && (
        <DropdownMenuWrapper ref={ref} role="menu" data-testid="dropdown-menu" {...others}>
          {children}
        </DropdownMenuWrapper>
      )}
      {footer && <GenericDropdownMenuWrapper>{footer}</GenericDropdownMenuWrapper>}
    </Popover>
  )
})

export function DropdownMenuItem(props: DropdownMenuItemProps): JSX.Element {
  const context = useContext(DropdownContext)
  const { leading, children, trailing, onClick, ...others } = props
  const id = useID(others.id)

  if (!context) {
    throw new Error('DropdownMenuItem must be inside a DropdownContext')
  }

  const { toggle } = context

  return (
    <DropdownItemWrapper
      role="menuitem"
      type="button"
      data-testid="dropdown-menu-item"
      {...others}
      aria-labelledby={id}
      onClick={(e) => {
        e.persist()

        const shouldKeepOpen = onClick?.(e)

        if (!shouldKeepOpen) {
          toggle()
        }
      }}
    >
      {leading && <Leading>{leading}</Leading>}
      <Children id={id}>{children}</Children>
      {trailing && <Trailing>{trailing}</Trailing>}
    </DropdownItemWrapper>
  )
}

export function DropdownMenuSection(props: DropdownMenuSectionProps): JSX.Element {
  const { children, header, ...others } = props

  return (
    <DropdownSectionWrapper data-testid="dropdown-menu-section" {...others} role="presentation">
      <DropdownSectionHeader>{header}</DropdownSectionHeader>
      {children}
    </DropdownSectionWrapper>
  )
}
