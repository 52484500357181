import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'

import conditional, { whenProps } from 'tools/conditional'
import { getToken as token } from 'theming'

interface WithScaleProps {
  scale?: 'small' | 'default' | 'large'
}

export interface ModalProps extends WithScaleProps {
  className?: string
  id?: string
  open: boolean
  fullscreen?: boolean
  onOverlayClick?: () => void
}

interface ModalWrapperProps {
  className?: string
  id?: string
  open: boolean
  fullscreen: boolean
}

interface ContentProps extends WithScaleProps {
  fullscreen: boolean
}

const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;

  z-index: ${token('z-index-overlay')};

  display: ${conditional({
    flex: whenProps({ open: true }),
    none: whenProps({ open: false }),
  })};
  align-items: center;
  justify-content: center;
  width: 100%;

  height: 100%;

  background: ${token('modal-overlay-background')};
`

const Content = styled.div<ContentProps>`
  background: white;
  border-radius: ${token('border-radius-s')};
  box-shadow: ${token('shadow-modal')};

  margin: 0 auto;

  position: relative;

  outline: none;

  z-index: ${token('z-index-modal')};

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100vh;
    `}

  ${({ fullscreen }) =>
    !fullscreen &&
    css`
      position: relative;
      left: auto;

      width: 80%;
      max-width: ${conditional({
        'modal-small-max-width': whenProps({ scale: 'small' }),
        'modal-max-width': whenProps({ scale: 'default' }),
        'modal-large-max-width': whenProps({ scale: 'large' }),
      })};
      height: auto;
    `}
`

function Modal({
  scale = 'default',
  children,
  open,
  fullscreen = false,
  onOverlayClick = () => null,
  ...others
}: PropsWithChildren<ModalProps>): JSX.Element {
  return ReactDOM.createPortal(
    <ModalWrapper
      open={open}
      fullscreen={fullscreen}
      onClick={onOverlayClick}
      data-testid="modal-overlay"
      {...others}
    >
      <Content
        role="dialog"
        aria-modal="true"
        fullscreen={fullscreen}
        scale={scale}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </Content>
    </ModalWrapper>,
    document.body
  )
}

export default Modal
