import React, { LabelHTMLAttributes, ReactNode } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'

import ColorScheme from 'utils/types/ColorScheme'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import font from 'styles/font'
import { getToken as token } from 'theming'
import transition from 'styles/transition'

export interface SelectionStyleProps {
  scale?: 'small' | 'default'
  scheme?: ColorScheme
  disabled?: boolean
}

export interface SelectionWrapperProps
  extends LabelHTMLAttributes<HTMLLabelElement>,
    SelectionStyleProps {
  selector: ReactNode
  leading?: ReactNode
  trailing?: ReactNode
}

const SelectorWrapper = styled.span`
  position: relative;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`

const Leading = styled(StyledSpan)`
  /* placeholder */
`

const Trailing = styled(StyledSpan)`
  /* placeholder */
`

const Children = styled.span`
  /* placeholder */
`

const StyledLabel = styled.label<SelectionStyleProps>`
  box-sizing: border-box;

  ${transition()}
  ${font({
    weight: 'checkbox-font-weight',
  })}

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  font-size: ${conditional({
    'checkbox-font-size': whenProps({ scale: 'default' }),
    'checkbox-small-font-size': whenProps({ scale: 'small' }),
  })};

  color: ${conditional({
    'checkbox-color': whenProps({ scheme: 'light' }),
    'checkbox-dark-color': whenProps({ scheme: 'dark' }),
  })};

  ${Children} {
    margin: 0 ${token('space-xs')};
  }

  ${SelectorWrapper} {
    margin: 0 ${token('space-xs')} 0 0;
  }

  ${Leading} {
    margin: 0 ${token('space-xs')};
  }

  ${Trailing} {
    margin: 0 0 0 ${token('space-xs')};
  }

  ${disableable()}
`

function SelectionWrapper({
  selector,
  children,
  leading,
  trailing,
  className,
  disabled = false,
  ...others
}: SelectionWrapperProps): JSX.Element {
  return (
    <StyledLabel
      className={clsx(className, {
        'is-disabled': disabled,
      })}
      {...others}
    >
      {selector && <SelectorWrapper>{selector}</SelectorWrapper>}
      {leading && <Leading data-testid="selection-leading">{leading}</Leading>}
      <Children>{children}</Children>
      {trailing && <Trailing data-testid="selection-trailing">{trailing}</Trailing>}
    </StyledLabel>
  )
}

export default SelectionWrapper
