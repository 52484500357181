import styled from 'styled-components'

import { BaseButton } from 'components/Button'
import { getToken as token } from 'theming'
import activatable from 'styles/activatable'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import typography from 'styles/typography'

const PickerButton = styled(BaseButton)<{
  $status: 'default' | 'current-day' | 'faded' | 'selection-start' | 'selection-end' | 'selection'
}>`
  ${typography(
    conditional({
      body: whenProps({ $status: ['default', 'faded'] }),
      'body-bold': whenProps({
        $status: ['current-day', 'selection-start', 'selection', 'selection-end'],
      }),
    })
  )}

  user-select: none;

  height: 100%;

  border-radius: 0;

  color: ${conditional({
    'color-neutral-lighter': whenProps({
      $status: ['selection-start', 'selection', 'selection-end'],
    }),
    'color-neutral-darker': whenProps({ $status: ['default', 'faded'] }),
    'color-accent': whenProps({ $status: 'current-day' }),
  })};

  background: ${conditional({
    'color-transparent': whenProps({ $status: ['default', 'faded'] }),
    'color-neutral-darker': whenProps({ $status: ['selection-start', 'selection-end'] }),
    'color-neutral': whenProps({ $status: 'selection' }),
    'color-neutral-lightest': whenProps({ $status: 'current-day' }),
  })};

  border-color: ${conditional({
    'color-transparent': whenProps({ $status: ['default', 'faded'] }),
    'color-neutral-darker': whenProps({ $status: ['selection-start', 'selection-end'] }),
    'color-neutral': whenProps({ $status: 'selection' }),
    'color-neutral-light': whenProps({ $status: 'current-day' }),
  })};

  ${conditional({
    'opacity: 0.6;': whenProps({ $status: 'faded' }),
    'opacity: 1;': whenProps({ $status: (status) => status !== 'faded' }),
  })}

  padding: ${token('space-xs')} ${token('space-s')};

  ${hoverable`
    border-color: ${token('color-neutral')};
    background-color: ${conditional({
      'color-transparent': whenProps({ $status: ['default', 'faded'] }),
      'color-neutral-dark': whenProps({
        $status: ['selection-start', 'selection', 'selection-end'],
      }),
      'color-neutral-lightest': whenProps({ $status: 'current-day' }),
    })};
    color: ${conditional({
      'color-neutral-dark': whenProps({ $status: ['default', 'faded'] }),
      'color-neutral-lightest': whenProps({
        $status: ['selection-start', 'selection', 'selection-end'],
      }),
    })};

    font-weight: ${token('font-weight-bold')};
  `}

  ${focusable`
    box-shadow: ${token('button-primary-outline')};
    font-weight: ${token('font-weight-bold')};
  `}

  ${activatable`
    border-color: ${token('color-neutral')};
    background-color: ${conditional({
      'color-transparent': whenProps({ 'aria-checked': false }),
      'color-neutral': whenProps({ 'aria-checked': true }),
    })};
    color: ${conditional({
      'color-neutral': whenProps({ 'aria-checked': false }),
      'color-neutral-white': whenProps({ 'aria-checked': true }),
    })};
  `}

  ${disableable`
    background-color: ${token('color-transparent')};
    border-color: ${conditional({
      'color-neutral-darkest': whenProps({ 'aria-checked': true }),
      'color-transparent': whenProps({ 'aria-checked': false }),
    })};

    color: ${token('color-neutral-darkest')};
  `}

  visibility: ${conditional({
    visible: whenProps({ 'aria-hidden': false }),
    hidden: whenProps({ 'aria-hidden': true }),
  })};
`

export default styled(PickerButton)({})
