import React, { forwardRef } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import { getToken as token } from 'theming'
import { useFocusWithin } from 'hooks/useFocusWithin'
import ColorScheme from 'utils/types/ColorScheme'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import font from 'styles/font'
import hoverable from 'styles/hoverable'
import Status from 'utils/types/Status'
import transition from 'styles/transition'

import type { TextareaHTMLAttributes, ReactNode } from 'react'

interface WithAdditionalProps {
  scale?: 'small' | 'default' | 'large'
  status?: Status
  scheme?: ColorScheme
  leading?: ReactNode
  trailing?: ReactNode
}

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    WithAdditionalProps {
  className?: string
  children?: ReactNode
}

const TextareaWrapper = styled.div<WithAdditionalProps>`
  ${transition()}

  display: inline-flex;
  flex-flow: row nowrap;

  min-height: ${token('textarea-min-height')};

  ${font({
    height: 'textarea-font-height',
  })}

  font-size: ${conditional({
    'textarea-font-size': whenProps({ scale: 'default' }),
    'textarea-small-font-size': whenProps({ scale: 'small' }),
    'textarea-large-font-size': whenProps({ scale: 'large' }),
  })};

  border-radius: ${token('textarea-border-radius')};
  border-width: 1px;
  border-style: solid;

  background: ${conditional({
    'textarea-background': whenProps({ scheme: 'light' }),
    'textarea-dark-background': whenProps({ scheme: 'dark' }),
  })};

  border-color: ${conditional({
    'textarea-border-color': whenProps({ scheme: 'light', status: Status.Neutral }),
    'textarea-dark-border-color': whenProps({ scheme: 'dark', status: Status.Neutral }),
    'textarea-danger-border-color': whenProps({ status: Status.Danger }),
    'textarea-success-border-color': whenProps({ status: Status.Success }),
  })};

  color: ${conditional({
    'textarea-color': whenProps({ scheme: 'light' }),
    'textarea-dark-color': whenProps({ scheme: 'dark' }),
  })};

  ${hoverable`
    background: ${conditional({
      'textarea-background--hover': whenProps({ scheme: 'light' }),
      'textarea-dark-background--hover': whenProps({ scheme: 'dark' }),
    })};

    border-color: ${conditional({
      'textarea-border-color--hover': whenProps({ scheme: 'light' }),
      'textarea-dark-border-color--hover': whenProps({ scheme: 'dark' }),
    })};
  `}

  ${focusable`
    background: ${conditional({
      'textarea-background--focus': whenProps({ scheme: 'light' }),
      'textarea-dark-background--focus': whenProps({ scheme: 'dark' }),
    })};

    border-color: ${conditional({
      'textarea-border-color--focus': whenProps({ scheme: 'light' }),
      'textarea-dark-border-color--focus': whenProps({ scheme: 'dark' }),
    })};

    box-shadow: ${conditional({
      'textarea-outline': whenProps({ scheme: 'light' }),
      'textarea-dark-outline': whenProps({ scheme: 'dark' }),
    })};
  `}

  ${disableable()}
`

const StyledSpan = styled.span<WithAdditionalProps>`
  ${transition()}

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  color: ${conditional({
    'textarea-border-color': whenProps({ scheme: 'light', status: Status.Neutral }),
    'textarea-dark-border-color': whenProps({ scheme: 'dark', status: Status.Neutral }),
    'textarea-danger-border-color': whenProps({ status: Status.Danger }),
    'textarea-success-border-color': whenProps({ status: Status.Success }),
  })};
`

const Leading = styled(StyledSpan)<WithAdditionalProps>`
  align-items: flex-start;

  margin: 0 0 0
    ${conditional({
      'text-field-padding-x': whenProps({ scale: ['default', 'large'] }),
      'text-field-small-padding-x': whenProps({ scale: 'small' }),
    })};

  padding: ${token('space-s')} 0;
`

const Trailing = styled(StyledSpan)<WithAdditionalProps>`
  align-items: flex-start;

  margin: 0
    ${conditional({
      'text-field-padding-x': whenProps({ scale: ['default', 'large'] }),
      'text-field-small-padding-x': whenProps({ scale: 'small' }),
    })}
    0 0;

  padding: ${token('space-s')} 0;
`

const StyledTextarea = styled.textarea<WithAdditionalProps>`
  ${transition()}

  ${font({
    height: 'textarea-font-height',
  })}

  background: transparent;

  padding: ${conditional({
      'textarea-padding-y': whenProps({ scale: 'default' }),
      'textarea-small-padding-y': whenProps({ scale: 'small' }),
      'textarea-large-padding-y': whenProps({ scale: 'large' }),
    })}
    0;

  margin: 0
    ${conditional({
      'textarea-padding-x': whenProps({ scale: ['default', 'large'] }),
      'textarea-small-padding-x': whenProps({ scale: 'small' }),
    })};

  border: none;

  flex: 1 0 auto;

  ${focusable()}
  ${disableable()}
`

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  props: TextareaProps,
  ref
): JSX.Element {
  const {
    disabled = false,
    status = Status.Neutral,
    scheme = 'light',
    scale = 'default',
    className,
    leading,
    trailing,
    ...others
  } = props
  const { ref: containerRef, focused } = useFocusWithin<HTMLDivElement>()

  return (
    <TextareaWrapper
      className={clsx(className, {
        'is-disabled': disabled,
        'is-focused': focused,
      })}
      scheme={scheme}
      scale={scale}
      status={status}
      ref={containerRef}
    >
      {leading && (
        <Leading status={status} scheme={scheme} scale={scale}>
          {leading}
        </Leading>
      )}
      <StyledTextarea
        ref={ref}
        data-testid="textarea"
        {...others}
        scale={scale}
        status={status}
        scheme={scheme}
        leading={leading}
        trailing={trailing}
        disabled={disabled}
      />
      {trailing && (
        <Trailing status={status} scheme={scheme} scale={scale}>
          {trailing}
        </Trailing>
      )}
    </TextareaWrapper>
  )
})

export default Textarea
