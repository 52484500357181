import toArray from './toArray'

import type { KeyboardEvent } from 'react'

// this is merely a helper for  key code names
const SUPPORTED_KEYS = {
  ALT: 'Alt',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  CONTROL: 'Control',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SHIFT: 'Shift',
  SPACE: ' ',
  TAB: 'Tab',
}

export type SupportedKey = keyof typeof SUPPORTED_KEYS

// Based on https://github.com/downshift-js/downshift/blob/26c93a539dad09e41adba69ddc3a7d7ecccfc8bb/src/utils.js#L285
export function getKeyboardKey(e: KeyboardEvent): KeyboardEvent['key'] {
  // IE
  if (e.keyCode >= 37 && e.keyCode <= 40 && e.key.indexOf('Arrow') !== 0) {
    return `Arrow${e.key}`
  }

  return e.key
}

function KeyboardKey(
  e: KeyboardEvent
): {
  is(otherKeys: SupportedKey | SupportedKey[]): boolean
} {
  const key = getKeyboardKey(e)

  return {
    is(otherKeys: SupportedKey | SupportedKey[]) {
      const expectedKeys = toArray(otherKeys).map((otherKey) => SUPPORTED_KEYS[otherKey])

      return toArray(expectedKeys).includes(key)
    },
  }
}

export default KeyboardKey
