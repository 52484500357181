import React from 'react'
import styled from 'styled-components'

import prop from 'tools/prop'

import { getSpace } from 'components/Layout/Layout.utils'
import { Spacing } from './Layout.types'

import type { HTMLAttributes } from 'react'

export interface GridProps extends HTMLAttributes<HTMLElement> {
  space?: Spacing
  minColumnWidth?: string
}

const GridContainer = styled.div<{
  $space: GridProps['space']
  $minColumnWidth: GridProps['minColumnWidth']
}>`
  display: grid;
  grid-gap: ${getSpace('$space')};

  @supports (width: min(${prop('$minColumnWidth')}, 100%)) {
    & {
      grid-template-columns: repeat(auto-fit, minmax(min(${prop('$minColumnWidth')}, 100%), 1fr));
    }
  }
`

/**
 * Grid layout component.
 * @param {GridProps} props
 */
export function Grid(props: GridProps): JSX.Element {
  const { space = 'm', minColumnWidth = '50%', children, ...rest } = props

  return (
    <GridContainer $space={space} $minColumnWidth={minColumnWidth} {...rest}>
      {children}
    </GridContainer>
  )
}
