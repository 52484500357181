import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Icon } from 'components/Icon'

// tools
import conditional, { whenProps } from 'tools/conditional'
// styles
import typography from 'styles/typography'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import transition from 'styles/transition'
import { getToken as token } from 'theming'

import type { ButtonHTMLAttributes } from 'react'

// interfaces
interface WithSizeProps {
  size?: 'small' | 'default' | 'large'
}

interface WithVariantProps {
  variant?: 'accent' | 'danger' | 'default' | 'outlined' | 'success' | 'warning'
}

export interface TagProps extends React.HTMLAttributes<HTMLElement>, WithSizeProps {
  className?: string
  children?: ReactNode
  disabled?: boolean
  leading?: ReactNode
  removable?: boolean
  variant?: 'accent' | 'danger' | 'default' | 'outlined' | 'success' | 'warning'
  onRemove?: () => void
  getRemoveButtonProps?: () => Partial<ButtonHTMLAttributes<HTMLButtonElement>> & {
    'data-testid'?: string
  }
}

// styled elements
const StyledTag = styled.span`
  display: inline-flex;

  position: relative;

  width: auto;

  z-index: 0;

  ${disableable`
    opacity: ${token('opacity-60')};
  `}
`

const StyledSpan = styled.span<TagProps>`
  ${transition()}

  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  text-decoration: none;

  ${typography(
    conditional({
      'chips-sm': whenProps({ size: 'small' }),
      'caption-bold': whenProps({ size: 'default' }),
      'body-bold': whenProps({ size: 'large' }),
    })
  )}

  line-height: initial;

  text-transform: ${conditional({
    'tag-transform': whenProps({ size: 'default' }),
    'tag-small-transform': whenProps({ size: 'small' }),
    'tag-large-transform': whenProps({ size: 'large' }),
  })};

  padding: ${conditional({
    'tag-spacing': whenProps({ removable: (removable) => !removable }),
    'tag-spacing-removable': whenProps([{ size: (size) => size !== 'small', removable: true }]),
    'tag-small-spacing-removable': whenProps([{ size: 'small', removable: true }]),
  })};

  min-width: ${token('tag-width')};
  height: ${conditional({
    'tag-height': whenProps({ size: 'default' }),
    'tag-small-height': whenProps({ size: 'small' }),
    'tag-large-height': whenProps({ size: 'large' }),
  })};

  color: ${conditional({
    'tag-default-color': whenProps({ variant: 'default' }),
    'tag-outlined-color': whenProps({ variant: 'outlined' }),
    'tag-accent-color': whenProps({ variant: 'accent' }),
    'tag-success-color': whenProps({ variant: 'success' }),
    'tag-warning-color': whenProps({ variant: 'warning' }),
    'tag-danger-color': whenProps({ variant: 'danger' }),
  })};

  background: ${conditional({
    'tag-default-background': whenProps({ variant: 'default' }),
    'tag-outlined-background': whenProps({ variant: 'outlined' }),
    'tag-accent-background': whenProps({ variant: 'accent' }),
    'tag-success-background': whenProps({ variant: 'success' }),
    'tag-warning-background': whenProps({ variant: 'warning' }),
    'tag-danger-background': whenProps({ variant: 'danger' }),
  })};

  border-radius: ${token('tag-border-radius')};
  border-width: ${token('tag-border-width')};
  border-style: solid;

  border-color: ${conditional({
    'tag-default-border-color': whenProps({ variant: 'default' }),
    'tag-outlined-border-color': whenProps({ variant: 'outlined' }),
    'tag-accent-border-color': whenProps({ variant: 'accent' }),
    'tag-success-border-color': whenProps({ variant: 'success' }),
    'tag-warning-border-color': whenProps({ variant: 'warning' }),
    'tag-danger-border-color': whenProps({ variant: 'danger' }),
  })};

  ${hoverable`
    background: ${conditional({
      'tag-default-background--hover': whenProps([
        { variant: 'default', removable: true },
        { variant: 'default', href: Boolean },
      ]),
      'tag-outlined-background--hover': whenProps([
        { variant: 'outlined', removable: true },
        { variant: 'outlined', href: Boolean },
      ]),
      'tag-accent-background--hover': whenProps([
        { variant: 'accent', removable: true },
        { variant: 'accent', href: Boolean },
      ]),
      'tag-success-background--hover': whenProps([
        { variant: 'success', removable: true },
        { variant: 'success', href: Boolean },
      ]),
      'tag-warning-background--hover': whenProps([
        { variant: 'warning', removable: true },
        { variant: 'warning', href: Boolean },
      ]),
      'tag-danger-background--hover': whenProps([
        { variant: 'danger', removable: true },
        { variant: 'danger', href: Boolean },
      ]),
    })};

    border-color: ${conditional({
      'tag-default-border-color--hover': whenProps([
        { variant: 'default', removable: true },
        { variant: 'default', href: Boolean },
      ]),
      'tag-outlined-border-color--hover': whenProps([
        { variant: 'outlined', removable: true },
        { variant: 'outlined', href: Boolean },
      ]),
      'tag-accent-border-color--hover': whenProps([
        { variant: 'accent', removable: true },
        { variant: 'accent', href: Boolean },
      ]),
      'tag-success-border-color--hover': whenProps([
        { variant: 'success', removable: true },
        { variant: 'success', href: Boolean },
      ]),
      'tag-warning-border-color--hover': whenProps([
        { variant: 'warning', removable: true },
        { variant: 'warning', href: Boolean },
      ]),
      'tag-danger-border-color--hover': whenProps([
        { variant: 'danger', removable: true },
        { variant: 'danger', href: Boolean },
      ]),
    })};

    color: ${conditional({
      'tag-default-color--hover': whenProps([
        { variant: 'default', removable: true },
        { variant: 'default', href: Boolean },
      ]),
      'tag-outlined-color--hover': whenProps([
        { variant: 'outlined', removable: true },
        { variant: 'outlined', href: Boolean },
      ]),
      'tag-accent-color--hover': whenProps([
        { variant: 'accent', removable: true },
        { variant: 'accent', href: Boolean },
      ]),
      'tag-success-color--hover': whenProps([
        { variant: 'success', removable: true },
        { variant: 'success', href: Boolean },
      ]),
      'tag-warning-color--hover': whenProps([
        { variant: 'warning', removable: true },
        { variant: 'warning', href: Boolean },
      ]),
      'tag-danger-color--hover': whenProps([
        { variant: 'danger', removable: true },
        { variant: 'danger', href: Boolean },
      ]),
    })};
  `}

  ${focusable`
    color: ${conditional({
      'tag-default-color--focus': whenProps({ variant: 'default' }),
      'tag-outlined-color--focus': whenProps({ variant: 'outlined' }),
      'tag-accent-color--focus': whenProps({ variant: 'accent' }),
      'tag-success-color--focus': whenProps({ variant: 'success' }),
      'tag-warning-color--focus': whenProps({ variant: 'warning' }),
      'tag-danger-color--focus': whenProps({ variant: 'danger' }),
    })};
    background: ${conditional({
      'tag-default-background--focus': whenProps({ variant: 'default' }),
      'tag-outlined-background--focus': whenProps({ variant: 'outlined' }),
      'tag-accent-background--focus': whenProps({ variant: 'accent' }),
      'tag-success-background--focus': whenProps({ variant: 'success' }),
      'tag-warning-background--focus': whenProps({ variant: 'warning' }),
      'tag-danger-background--focus': whenProps({ variant: 'danger' }),
    })};
    box-shadow: ${token('tag-outline')};
  `}

  ${disableable`
    opacity: ${token('opacity-60')};
  `}
`

const Leading = styled.span<WithSizeProps>`
  display: ${conditional({
    'tag-leading-display': whenProps({ size: 'default' }),
    'tag-small-leading-display': whenProps({ size: 'small' }),
    'tag-large-leading-display': whenProps({ size: 'large' }),
  })};
  margin: 0 ${token('tag-icon-spacing')} 0 0;

  color: currentColor;

  fill: currentColor;
`

const StyledCloseButton = styled.button<WithSizeProps & WithVariantProps>`
  z-index: 2;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  background: ${token('tag-remove-button-background')};
  border-radius: ${token('tag-remove-button-border-radius')};
  border: 0;

  width: ${conditional({
    'tag-remove-button-size': whenProps({ size: 'default' }),
    'tag-small-remove-button-size': whenProps({ size: 'small' }),
    'tag-large-remove-button-size': whenProps({ size: 'large' }),
  })};

  height: ${conditional({
    'tag-remove-button-size': whenProps({ size: 'default' }),
    'tag-small-remove-button-size': whenProps({ size: 'small' }),
    'tag-large-remove-button-size': whenProps({ size: 'large' }),
  })};

  padding: 0;

  ${disableable`
    opacity: ${token('opacity-60')};
  `}

  ${focusable``}

  &:focus + ${StyledSpan} {
    color: ${conditional({
      'tag-default-color--focus': whenProps({ variant: 'default' }),
      'tag-outlined-color--focus': whenProps({ variant: 'outlined' }),
      'tag-accent-color--focus': whenProps({ variant: 'accent' }),
      'tag-success-color--focus': whenProps({ variant: 'success' }),
      'tag-warning-color--focus': whenProps({ variant: 'warning' }),
      'tag-danger-color--focus': whenProps({ variant: 'danger' }),
    })};

    background: ${conditional({
      'tag-default-background--focus': whenProps({ variant: 'default' }),
      'tag-outlined-background--focus': whenProps({ variant: 'outlined' }),
      'tag-accent-background--focus': whenProps({ variant: 'accent' }),
      'tag-success-background--focus': whenProps({ variant: 'success' }),
      'tag-warning-background--focus': whenProps({ variant: 'warning' }),
      'tag-danger-background--focus': whenProps({ variant: 'danger' }),
    })};
    box-shadow: ${token('tag-outline')};
  }
`

const CloseIcon = styled(Icon)<WithSizeProps & WithVariantProps>`
  width: ${conditional({
    'tag-remove-button-icon-size': whenProps({ size: 'default' }),
    'tag-small-remove-button-icon-size': whenProps({ size: 'small' }),
    'tag-large-remove-button-icon-size': whenProps({ size: 'large' }),
  })};

  height: ${conditional({
    'tag-remove-button-icon-size': whenProps({ size: 'default' }),
    'tag-small-remove-button-icon-size': whenProps({ size: 'small' }),
    'tag-large-remove-button-icon-size': whenProps({ size: 'large' }),
  })};

  color: ${conditional({
    'tag-default-color': whenProps({ variant: 'default' }),
    'tag-outlined-color': whenProps({ variant: 'outlined' }),
    'tag-accent-color': whenProps({ variant: 'accent' }),
    'tag-success-color': whenProps({ variant: 'success' }),
    'tag-warning-color': whenProps({ variant: 'warning' }),
    'tag-danger-color': whenProps({ variant: 'danger' }),
  })};

  fill: currentColor;
`

const StyledSpacer = styled.span<WithSizeProps>`
  display: inline-block;
  width: ${conditional({
    '0': whenProps({ size: 'small' }),
    'space-xs': whenProps({ size: 'default' }),
    'space-s': whenProps({ size: 'large' }),
  })};
`

// components
function Tag(props: TagProps): JSX.Element {
  const {
    children,
    leading,
    size,
    onRemove,
    variant,
    removable,
    getRemoveButtonProps,
    disabled = false,
    ...others
  } = props

  return (
    <StyledTag>
      <StyledSpan size={size} variant={variant} {...others}>
        {leading && <Leading size={size}>{leading}</Leading>}
        {children}
        {removable && !disabled && (
          <>
            <StyledSpacer aria-hidden="true" size={size} />
            <StyledCloseButton {...getRemoveButtonProps?.()} size={size} onClick={onRemove}>
              <CloseIcon name="close" variant={variant} size={size} />
            </StyledCloseButton>
          </>
        )}
      </StyledSpan>
    </StyledTag>
  )
}

export default Tag
