import { useCallback, useState } from 'react'

import type { ChangeEvent } from 'react'
import type { TextFieldProps } from './TextField'

type InputValue = string | number | readonly string[] | undefined
type ReturnType = [InputValue, (e: ChangeEvent<HTMLInputElement>) => void]

function useTextField({ onChange: onChangeProp, value: valueProp }: TextFieldProps): ReturnType {
  const [value, setValue] = useState(valueProp || '')

  const onChange = useCallback(
    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      const {
        target: { value },
      } = e
      setValue(value)
      onChangeProp?.(e)
    },
    [onChangeProp]
  )

  return [value, onChange]
}

export default useTextField
