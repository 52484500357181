import React from 'react'
import styled from 'styled-components'
import prop from 'tools/prop'

import { Spacing } from './Layout.types'
import { getSpace } from './Layout.utils'

import type { HTMLAttributes } from 'react'

export interface SwitcherProps extends HTMLAttributes<HTMLElement> {
  space?: Spacing
  threshold?: string
  limit?: number
}

const SwitcherContainer = styled.div<{
  $space: SwitcherProps['space']
  $threshold: SwitcherProps['threshold']
  $limit: SwitcherProps['limit']
}>`
  display: flex;
  flex-wrap: wrap;
  gap: ${getSpace('$space')};

  > * {
    flex-basis: calc((${prop('$threshold')} - 100%) * 999);
    flex-grow: 1;
  }

  & > :nth-last-child(n + ${prop('$limit')}),
  & > :nth-last-child(n + ${prop('$limit')}) ~ * {
    flex-basis: 100%;
  }
`

/**
 * Switcher layout component.
 * @param {SwitcherProps} props
 */
export function Switcher(props: SwitcherProps): JSX.Element {
  const { limit = 4, space = 's', threshold = '10rem', children, ...rest } = props

  return (
    <SwitcherContainer $space={space} $threshold={threshold} $limit={limit + 1} {...rest}>
      {children}
    </SwitcherContainer>
  )
}
