import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import typography, { TypographyOptions, TypographyVariants } from 'styles/typography'

export type TextProps = PropsWithChildren<{
  variant?: TypographyVariants
  italic?: TypographyOptions['italic']
  color?: TypographyOptions['color']
}>

export default styled.span<TextProps>`
  ${({ variant, italic, color }) => typography(variant, { italic, color })}
`
