import { ThemeProps } from 'styled-components'
import { isFunction } from '@loadsmart/utils-function'
import ThemeAlice from './themes/alice.theme'

export type ThemeToken = keyof typeof ThemeAlice
export type ThemeTokenValue = string | number

export type CustomTheme = Record<ThemeToken, ThemeTokenValue>
export type ThemedProps = ThemeProps<CustomTheme>

type TokenLike<P extends ThemedProps = ThemedProps> = ThemeToken | ((props: P) => ThemeToken)

function getTokenFromTheme<P extends ThemedProps>(token: TokenLike<P>, props: P): ThemeTokenValue {
  const tokenName = isFunction(token) ? token(props) : token
  return props.theme[tokenName]
}

/**
 * Return a design token value for the current theme.
 * @param {string} token - Token whose value should be returned.
 * @param {[ThemedStyledProps]} props - Component props.
 * @returns {ThemeTokenValue} Token value or `undefined` if the token was not found for the current theme.
 */
export function getToken<P extends ThemedProps>(
  token: TokenLike<P>,
  props?: P
): ThemeTokenValue | ((props: P) => ThemeTokenValue) {
  if (props === undefined) {
    return (props) => getTokenFromTheme(token, props)
  }

  return getTokenFromTheme(token, props)
}

export * as Themes from './themes'
