import React, { createContext, ReactNode, useState } from 'react'

type AccordionContextType = [boolean, () => void]
const AccordionContext = createContext<AccordionContextType | undefined>(undefined)

function AccordionProvider({
  expanded,
  children,
}: {
  children: ReactNode
  expanded?: boolean
}): JSX.Element {
  const [open, setOpen] = useState(expanded ?? false)

  function toggle() {
    setOpen(!open)
  }

  return <AccordionContext.Provider value={[open, toggle]}>{children}</AccordionContext.Provider>
}

function useAccordionContext(): AccordionContextType {
  const context = React.useContext(AccordionContext)
  if (!context) {
    throw new Error('useAccordionContext must be used within an AccordionProvider')
  }
  return context
}

export { AccordionProvider, useAccordionContext }
