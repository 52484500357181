import { identity } from '@loadsmart/utils-function'
import { padded } from './Date.helper'

import type { CalendarDate } from './Date.helper'

export interface DateFormat {
  format(date: CalendarDate): string
}

/**
 * This helpers provides a convenient layer on top of `Intl.DateTimeFormat`,
 * using common tokens (based on `momentjs`) to format dates.
 */
export default function DateFormatHelper(format: string): DateFormat {
  const tokens = tokenizer(format)

  return {
    format(date: CalendarDate) {
      return tokens
        .map((token) => {
          if (token in DEFAULT_FORMATTERS) {
            const value = DEFAULT_FORMATTERS[token].format(date.get())

            return (ADDITIONAL_FORMATTERS[token] || identity)(value)
          }

          return token
        })
        .join('')
    },
  }
}

/**
 * Supported tokens:
 *
 *|                                | Token | Output                                    |
 *| :----------------------------- | :---- | :---------------------------------------- |
 *| Month                          | MM    | 01, 02, ..., 11, 12                       |
 *|                                | MMM   | Jan, Feb, ..., Nov, Dec                   |
 *|                                | MMMM  | January, February, ..., November,December |
 *| Day of Month                   | DD    | 01, 02, ..., 30, 31                       |
 *| Day of week                    | ddd   | Sun, Mon, ... Fri, Sat                    |
 *|                                | dddd  | Sunday, Monday, ..., Friday, Saturday     |
 *| Year                           | YYYY  | 1970, 1971, ..., 2029, 2030               |
 *| Hour                           | HH    | 00, 01, ..., 22, 23                       |
 *| Minute                         | mm    | 01, 02, ..., 11, 12                       |
 *| Seconds                        | ss    | 01, 02, ..., 58, 59                       |
 *| Scaped sequence                | []    |                                           |
 *
 * @param format
 * @returns
 */
export function tokenizer(format: string): string[] {
  function getType(char?: string): string {
    if (char != undefined && ['M', 'd', 'D', 'Y', 'H', 'm', 's'].includes(char)) {
      return 'token'
    }

    return 'string'
  }

  const tokens: string[] = []

  let i = 0
  let prev
  let isEscaping = false

  while (i < format.length) {
    const at = Math.max(0, tokens.length - 1)

    const char = format.charAt(i)
    i++

    if (['[', ']'].includes(char)) {
      isEscaping = char === '['
    } else if (isEscaping) {
      tokens[at] = `${tokens[at] || ''}${char}`
    } else if (prev !== char && [getType(prev), getType(char)].includes('token')) {
      // we just need to start a new piece of string if we found a possible valid token
      tokens.push(char)
    } else {
      tokens[at] = `${tokens[at] || ''}${char}`
    }

    prev = char
  }

  return tokens
}

/**
 * TODO: Evaluate the need to add the following pollyfills:
 * - https://formatjs.io/docs/polyfills/intl-datetimeformat/
 * - https://formatjs.io/docs/polyfills/intl-getcanonicallocales/
 * - https://formatjs.io/docs/polyfills/intl-locale/
 * - https://formatjs.io/docs/polyfills/intl-numberformat/
 * - https://formatjs.io/docs/polyfills/intl-pluralrules/
 */
const DEFAULT_FORMATTERS: Record<string, Intl.DateTimeFormat> = {
  MM: new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
  }),
  MMM: new Intl.DateTimeFormat('en-US', {
    month: 'short',
  }),
  MMMM: new Intl.DateTimeFormat('en-US', {
    month: 'long',
  }),
  DD: new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
  }),
  ddd: new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }),
  dddd: new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
  }),
  YYYY: new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
  }),
  HH: new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    hour12: false,
  }),
  mm: new Intl.DateTimeFormat('en-US', {
    minute: '2-digit',
    hour12: false,
  }),
  ss: new Intl.DateTimeFormat('en-US', {
    second: '2-digit',
    hour12: false,
  }),
}

/**
 * Apply additional formatting.
 *
 * Padding, for example, is applied in some cases due to [this](https://bugs.chromium.org/p/chromium/issues/detail?id=527926) bug.
 */
const ADDITIONAL_FORMATTERS: Record<string, (value: string) => string> = {
  HH: (value: string) => padded(value, 2),
  mm: (value: string) => padded(value, 2),
  ss: (value: string) => padded(value, 2),
}
