import { createContext } from 'react'
import type { DropdownContextReturn } from './Dropdown.types'

const DropdownContext = createContext<DropdownContextReturn>({
  disabled: false,
  expanded: false,
  toggle: function () {
    // nothing
  },
})

export default DropdownContext
