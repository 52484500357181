/* eslint-disable @typescript-eslint/no-explicit-any */
import interleave from 'utils/toolset/interleave'
import { InterpolatedStyle } from 'utils/types/InterpolatedStyle'

/**
 * Add basic CSS style wrapping block for `:disabled` state or `.is-disabled` class.
 * It returns an array to allow handling of nested interpolation
 * @param {ReadonlyArray<string> | Array<string>} strings - String parts for the interpolation.
 * @param {any[]} args - Interpolated items.
 * @returns {any[]}
 */
function disableableStyle(
  strings?: ReadonlyArray<string> | Array<string>,
  ...args: any[]
): InterpolatedStyle {
  const safeStrings = (strings || []).concat() // ReadonlyArray -> Array

  return [
    `
    &.is-disabled,
    &:disabled {
      cursor: not-allowed;
      opacity: 0.40;

      user-select: none;

      &:active {
        pointer-events: none;
      }`,
    ...(interleave(safeStrings, args) as InterpolatedStyle),
    `
    }

    // prevent overlapping the opacity for nested disabled elements
    .is-disabled &.is-disabled,
    .is-disabled &[disabled],
    [disabled] &[disabled],
    [disabled] &.is-disabled {
      opacity: 1 !important;
    }
    `,
  ]
}

export default disableableStyle
