/* eslint-disable @typescript-eslint/no-explicit-any */
import interleave from 'utils/toolset/interleave'
import { InterpolatedStyle } from 'utils/types/InterpolatedStyle'

/**
 * Add basic CSS style wrapping block for `:hover` state; it includes check for non-disabled (`:enabled` or not `.is-disabled`) elements.
 * It returns an array to allow handling of nested interpolation
 * @param {ReadonlyArray<string> | Array<string>} strings - String parts for the interpolation.
 * @param {any[]} args - Interpolated items.
 * @returns {any[]}
 */
function hoverableStyle(
  strings?: ReadonlyArray<string> | Array<string>,
  ...args: any[]
): InterpolatedStyle {
  const safeStrings = (strings || []).concat() // ReadonlyArray -> Array

  return [
    `
    &:not(.is-disabled):not(:disabled) {
      &:hover {`,
    ...(interleave(safeStrings, args) as InterpolatedStyle),
    `
      }
    }
  `,
  ]
}

export default hoverableStyle
