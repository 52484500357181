import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import { Icon } from 'components/Icon'
import activatable from 'styles/activatable'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import ellipsizable from 'styles/ellipsizable'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import omit from 'utils/toolset/omit'
import rem from 'utils/toolset/rem'
import transition from 'styles/transition'
import typography from 'styles/typography'

import type { ButtonHTMLAttributes, ForwardedRef, ReactNode } from 'react'
import type { IconProps } from 'components/Icon'
import type ColorScheme from 'utils/types/ColorScheme'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  leading?: ReactNode
  scheme?: ColorScheme
  trailing?: ReactNode
  variant?: 'primary' | 'secondary' | 'warning' | 'icon' | 'tertiary'
  scale?: 'small' | 'default' | 'large'
}

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: ${rem('6px')} 0;
`

const Leading = styled(StyledSpan)`
  /* placeholder */
`

const Trailing = styled(StyledSpan)`
  /* placeholder */
`

const Children = styled.span`
  ${({ children }: ButtonProps) =>
    typeof children === 'string'
      ? `
    ${ellipsizable()}
  `
      : `
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
  `}

  padding: ${rem('6px')} 0;
`

const BaseStyledButton = styled.button<{ $scale: ButtonProps['scale'] }>`
  ${transition()}

  ${typography(
    conditional({
      'button-md': whenProps({ $scale: 'default' }),
      'button-sm': whenProps({ $scale: 'small' }),
      'button-lg': whenProps({ $scale: 'large' }),
    })
  )}

  box-sizing: border-box;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  text-align: center;

  cursor: pointer;

  border-radius: ${token('border-radius-s')};
  border-width: ${token('border-width-thin')};
  border-style: solid;
  border-color: ${token('color-neutral-darker')};

  height: ${conditional({
    'button-height': whenProps({ $scale: 'default' }),
    'button-small-height': whenProps({ $scale: 'small' }),
    'button-large-height': whenProps({ $scale: 'large' }),
  })};

  padding: 0 8px;

  ${disableable()}

  ${Leading} {
    margin: 0 ${token('space-xs')} 0 0;
  }

  ${Trailing} {
    margin: 0 0 0 ${token('space-xs')};
  }
`

const StyledButton = styled(BaseStyledButton)<{
  $variant: ButtonProps['variant']
  $scale: ButtonProps['scale']
  $scheme: ButtonProps['scheme']
}>`
  width: ${conditional({
    'button-width': whenProps({ $variant: ['primary', 'secondary', 'warning', 'tertiary'] }),
    'button-icon-width': whenProps({ $variant: 'icon', $scale: 'default' }),
    'button-icon-small-width': whenProps({ $variant: 'icon', $scale: 'small' }),
    'button-icon-large-width': whenProps({ $variant: 'icon', $scale: 'large' }),
  })};

  height: ${conditional({
    'button-height': whenProps({ $scale: 'default' }),
    'button-small-height': whenProps({ $scale: 'small' }),
    'button-large-height': whenProps({ $scale: 'large' }),
  })};

  color: ${conditional({
    'button-primary-color': whenProps({ $variant: 'primary' }),
    'button-secondary-color': whenProps({ $variant: ['secondary', 'tertiary'], $scheme: 'light' }),
    'button-secondary-dark-color': whenProps({
      $variant: ['secondary', 'tertiary'],
      $scheme: 'dark',
    }),
    'button-warning-color': whenProps({ $variant: 'warning' }),
    'button-icon-color': whenProps({ $variant: 'icon' }),
  })};

  background: ${conditional({
    'button-primary-background': whenProps({ $variant: 'primary' }),
    'button-secondary-background': whenProps({
      $variant: ['secondary', 'tertiary'],
      $scheme: 'light',
    }),
    'button-secondary-dark-background': whenProps({
      $variant: ['secondary', 'tertiary'],
      $scheme: 'dark',
    }),
    'button-warning-background': whenProps({ $variant: 'warning' }),
    'button-icon-background': whenProps({ $variant: 'icon' }),
  })};

  border-color: ${conditional({
    'button-primary-border-color': whenProps({ $variant: 'primary' }),
    'button-secondary-border-color': whenProps({ $variant: 'secondary', $scheme: 'light' }),
    'button-secondary-dark-border-color': whenProps({ $variant: 'secondary', $scheme: 'dark' }),
    'button-warning-border-color': whenProps({ $variant: 'warning' }),
    'button-icon-border-color': whenProps({ $variant: 'icon' }),
    'color-transparent': whenProps({ $variant: 'tertiary' }),
  })};
  border-radius: ${conditional({
    'button-border-radius': whenProps({ $variant: ['primary', 'secondary', 'warning'] }),
    'button-icon-border-radius': whenProps({ $variant: 'icon' }),
  })};

  ${hoverable`
    background: ${conditional({
      'button-primary-background--hover': whenProps({ $variant: 'primary' }),
      'button-secondary-background--hover': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-background--hover': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-background--hover': whenProps({ $variant: 'warning' }),
      'button-icon-background--hover': whenProps({ $variant: 'icon' }),
    })};
    border-color: ${conditional({
      'button-primary-border-color--hover': whenProps({ $variant: 'primary' }),
      'button-secondary-border-color--hover': whenProps({
        $variant: 'secondary',
        $scheme: 'light',
      }),
      'button-secondary-dark-border-color--hover': whenProps({
        $variant: 'secondary',
        $scheme: 'dark',
      }),
      'button-warning-border-color--hover': whenProps({ $variant: 'warning' }),
      'button-icon-border-color--hover': whenProps({ $variant: 'icon' }),
      'color-transparent': whenProps({ $variant: 'tertiary' }),
    })};
    color: ${conditional({
      'button-primary-color--hover': whenProps({ $variant: 'primary' }),
      'button-secondary-color--hover': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-color--hover': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-color--hover': whenProps({ $variant: 'warning' }),
      'button-icon-color--hover': whenProps({ $variant: 'icon' }),
    })};
  `}

  ${disableable`
    background: ${conditional({
      'button-primary-background--disabled': whenProps({ $variant: 'primary' }),
      'button-secondary-background--disabled': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-background--disabled': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-background--disabled': whenProps({ $variant: 'warning' }),
      'button-icon-background--disabled': whenProps({ $variant: 'icon' }),
    })};
    border-color: ${conditional({
      'button-primary-border-color--disabled': whenProps({ $variant: 'primary' }),
      'button-secondary-border-color--disabled': whenProps({
        $variant: 'secondary',
        $scheme: 'light',
      }),
      'button-secondary-dark-border-color--disabled': whenProps({
        $variant: 'secondary',
        $scheme: 'dark',
      }),
      'button-warning-border-color--disabled': whenProps({ $variant: 'warning' }),
      'button-icon-border-color--disabled': whenProps({ $variant: 'icon' }),
      'color-transparent': whenProps({ $variant: 'tertiary' }),
    })};
    color: ${conditional({
      'button-primary-color--disabled': whenProps({ $variant: 'primary' }),
      'button-secondary-color--disabled': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-color--disabled': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-color--disabled': whenProps({ $variant: 'warning' }),
      'button-icon-color--disabled': whenProps({ $variant: 'icon' }),
    })};
  `}

  ${focusable`
    background: ${conditional({
      'button-primary-background--focus': whenProps({ $variant: 'primary' }),
      'button-secondary-background--focus': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-background--focus': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-background--focus': whenProps({ $variant: 'warning' }),
      'button-icon-background--focus': whenProps({ $variant: 'icon' }),
    })};
    border-color: ${conditional({
      'button-primary-border-color--focus': whenProps({ $variant: 'primary' }),
      'button-secondary-border-color--focus': whenProps({
        $variant: 'secondary',
        $scheme: 'light',
      }),
      'button-secondary-dark-border-color--focus': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-border-color--focus': whenProps({ $variant: 'warning' }),
      'button-icon-border-color--focus': whenProps({ $variant: 'icon' }),
      'color-transparent': whenProps({ $variant: 'tertiary' }),
    })};
    color: ${conditional({
      'button-primary-color--focus': whenProps({ $variant: 'primary' }),
      'button-secondary-color--focus': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-color--focus': whenProps({ $variant: 'secondary', $scheme: 'dark' }),
      'button-warning-color--focus': whenProps({ $variant: 'warning' }),
      'button-icon-color--focus': whenProps({ $variant: 'icon' }),
    })};

    box-shadow: ${conditional({
      'button-primary-outline': whenProps({ $variant: 'primary' }),
      'button-secondary-outline': whenProps({ $variant: 'secondary' }),
      'button-warning-outline': whenProps({ $variant: 'warning' }),
      'button-icon-outline': whenProps({ $variant: 'icon' }),
    })}
  `}

  ${activatable`
    background: ${conditional({
      'button-primary-background--active': whenProps({ $variant: 'primary' }),
      'button-secondary-background--active': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-background--active': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'dark',
      }),
      'button-warning-background--active': whenProps({ $variant: 'warning' }),
      'button-icon-background--active': whenProps({ $variant: 'icon' }),
    })};
    border-color: ${conditional({
      'button-primary-border-color--active': whenProps({ $variant: 'primary' }),
      'button-secondary-border-color--active': whenProps({
        $variant: 'secondary',
        $scheme: 'light',
      }),
      'button-secondary-dark-border-color--active': whenProps({
        $variant: 'secondary',
        $scheme: 'dark',
      }),
      'button-warning-border-color--active': whenProps({ $variant: 'warning' }),
      'button-icon-border-color--active': whenProps({ $variant: 'icon' }),
      'color-transparent': whenProps({ $variant: 'tertiary' }),
    })};
    color: ${conditional({
      'button-primary-color--active': whenProps({ $variant: 'primary' }),
      'button-secondary-color--active': whenProps({
        $variant: ['secondary', 'tertiary'],
        $scheme: 'light',
      }),
      'button-secondary-dark-color--active': whenProps({ $variant: 'secondary', $scheme: 'dark' }),
      'button-warning-color--active': whenProps({ $variant: 'warning' }),
      'button-icon-color--active': whenProps({ $variant: 'icon' }),
    })};
  `}

  ${Children} {
    margin: 0 ${token('button-spacing-x')};
  }

  ${Leading} {
    margin: 0 0 0 ${token('button-spacing-x')};
  }

  ${Trailing} {
    margin: 0 ${token('button-spacing-x')} 0 0;
  }
`

const StyledSelector = styled(StyledButton)`
  ${Children} {
    flex: 1;
  }

  ${Trailing} {
    margin: 0;
  }
`

export const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { scale = 'default', children, leading, trailing, ...others }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <BaseStyledButton ref={ref} {...others} $scale={scale}>
      {leading && <Leading aria-hidden="true">{leading}</Leading>}
      <Children>{children}</Children>
      {trailing && <Trailing aria-hidden="true">{trailing}</Trailing>}
    </BaseStyledButton>
  )
})

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    type = 'button',
    scheme = 'light',
    scale = 'default',
    variant = 'secondary',
    children,
    leading,
    trailing,
    ...others
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <StyledButton
      ref={ref}
      {...others}
      type={type}
      $scheme={scheme}
      $scale={scale}
      $variant={variant}
    >
      {leading && <Leading>{leading}</Leading>}
      <Children>{children}</Children>
      {trailing && <Trailing>{trailing}</Trailing>}
    </StyledButton>
  )
})

export function Caret(props: Omit<IconProps, 'name'>): JSX.Element {
  return <Icon size={20} {...props} name="caret-down" />
}

export const SelectorButton = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    scheme = 'light',
    scale = 'default',
    type = 'button',
    variant = 'secondary',
    children,
    trailing,
    ...others
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  others = omit<ButtonProps>(others, ['leading'])

  return (
    <StyledSelector
      ref={ref}
      {...others}
      type={type}
      $scheme={scheme}
      $scale={scale}
      $variant={variant}
    >
      <Children>{children}</Children>
      {trailing && <Trailing>{trailing}</Trailing>}
    </StyledSelector>
  )
})

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { scheme = 'light', scale = 'default', type = 'button', children, ...others }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  others = omit<ButtonProps>(others, ['leading', 'trailing', 'variant'])

  return (
    <StyledButton ref={ref} {...others} type={type} $scheme={scheme} $scale={scale} $variant="icon">
      <Children>{children}</Children>
    </StyledButton>
  )
})

export default Button
