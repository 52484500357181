import React, { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import transition from 'styles/transition'
import conditional, { whenProps } from 'tools/conditional'
import { getToken as token } from 'theming'
import DefaultCloseButton from 'common/CloseButton'

import type { PropsWithChildren } from 'react'

export interface DrawerProps {
  children?: ReactNode
  className?: string
  open: boolean
  onClose?: () => void
}

const StyledAside = styled.aside<Pick<DrawerProps, 'open'>>`
  position: fixed;
  top: 0;
  left: 0;

  z-index: ${token('z-index-overlay')};

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background: ${token('modal-overlay-background')};

  visibility: ${conditional({
    visible: whenProps({ open: true }),
    hidden: whenProps({ open: false }),
  })};
`

const CloseButton = styled(DefaultCloseButton)`
  position: absolute;
  top: 32px;
  left: -48px;

  background-color: ${token('color-neutral-lighter')};
`

const StyledSection = styled.section<Pick<DrawerProps, 'open'>>`
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40vw;
  height: 100%;

  background-color: ${token('color-neutral-lightest')};
  border-radius: ${token('border-radius-s')};
  box-shadow: ${token('shadow-modal')};

  transform: ${conditional({
    'translateX(100%)': whenProps({
      open: false,
    }),
    'translateX(0px)': whenProps({
      open: true,
    }),
  })};

  ${transition({
    property: 'transform',
    duration: '200ms',
  })}
`

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${token('space-xl')};

  color: ${token('color-neutral-darker')};
  font-weight: ${token('font-weight-bold')};
  font-size: ${token('font-size-2')};
  text-align: center;

  border-bottom-color: ${token('color-neutral-light')};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${token('space-xl')};

  overflow: auto;
`

const StyledFooter = styled.footer`
  display: flex;

  padding: ${token('space-xl')};

  border-top-color: ${token('color-neutral-light')};
  border-top-width: 1px;
  border-top-style: solid;
`

export interface DrawerHeaderProps {
  className?: string
}

function DrawerHeader({
  children,
  className,
  ...others
}: PropsWithChildren<DrawerHeaderProps>): JSX.Element {
  return (
    <StyledHeader {...others} className={className}>
      {children}
    </StyledHeader>
  )
}

export interface DrawerBodyProps {
  className?: string
}

function DrawerBody({
  children,
  className,
  ...others
}: PropsWithChildren<DrawerHeaderProps>): JSX.Element {
  return (
    <StyledBody {...others} className={className}>
      {children}
    </StyledBody>
  )
}

export interface DrawerFooterProps {
  className?: string
}

function DrawerFooter({
  children,
  className,
  ...others
}: PropsWithChildren<DrawerFooterProps>): JSX.Element {
  return (
    <StyledFooter {...others} className={className}>
      {children}
    </StyledFooter>
  )
}

function Drawer({ className, children, open, onClose, ...others }: DrawerProps): JSX.Element {
  const [invisible, setInvisible] = useState(!open)

  function handleTransitionEnd() {
    if (!open) {
      setInvisible(true)
    }
  }

  useEffect(() => {
    if (open) {
      setInvisible(false)
    }
  }, [open])

  return ReactDOM.createPortal(
    <StyledAside open={!invisible}>
      <StyledSection
        {...others}
        className={className}
        open={open}
        onTransitionEnd={handleTransitionEnd}
        role="dialog"
        aria-modal="true"
        aria-label="drawer"
      >
        {Boolean(onClose) && <CloseButton onClick={onClose} aria-label="close" />}
        {children}
      </StyledSection>
    </StyledAside>,
    document.body
  )
}

Drawer.Header = DrawerHeader
Drawer.Body = DrawerBody
Drawer.Footer = DrawerFooter

export default Drawer
