import React from 'react'
import styled from 'styled-components'

import conditional, { whenProps } from 'tools/conditional'
import { Spacing } from './Layout.types'
import { getSpace } from './Layout.utils'

import type { HTMLAttributes } from 'react'

export interface GroupProps extends HTMLAttributes<HTMLElement> {
  space?: Spacing
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch'
}

const GroupContainer = styled.div<{
  $space: GroupProps['space']
  $justify: GroupProps['justify']
  $align: GroupProps['align']
}>`
  display: flex;
  flex-wrap: wrap;
  gap: ${getSpace('$space')};

  align-items: ${conditional({
    center: whenProps({ $align: 'center' }),
    'flex-start': whenProps({ $align: 'flex-start' }),
    'flex-end': whenProps({ $align: 'flex-end' }),
    stretch: whenProps({ $align: 'stretch' }),
  })};

  justify-content: ${conditional({
    center: whenProps({ $justify: 'center' }),
    'flex-start': whenProps({ $justify: 'flex-start' }),
    'flex-end': whenProps({ $justify: 'flex-end' }),
    'space-between': whenProps({ $justify: 'space-between' }),
    'space-around': whenProps({ $justify: 'space-around' }),
    'space-evenly': whenProps({ $justify: 'space-evenly' }),
  })};
`

/**
 * Group layout component.
 * @param {GroupProps} props
 */
export function Group(props: GroupProps): JSX.Element {
  const { space = 'm', justify = 'flex-start', align = 'flex-start', children, ...rest } = props

  return (
    <GroupContainer $space={space} $justify={justify} $align={align} {...rest}>
      {children}
    </GroupContainer>
  )
}
