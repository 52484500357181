import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import { Group } from 'components/Layout'
import { Text as DefaultText } from 'components/Text'
import { TODAY } from '../Date.helper'
import DateFormatHelper from '../DateFormat.helper'
import PickerButton from './PickerButton'

import type { CalendarDate } from '../Date.helper'
import type { CalendarPickerProps, RenderableMonth, useCalendarReturn } from '../Calendar.types'

export const WEEKDAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
export const formatter = DateFormatHelper('MM/DD/YYYY')

function getStatus(calendar: useCalendarReturn, date: CalendarDate) {
  if (calendar.selected[0] == date.getTime()) {
    return 'selection-start'
  } else if (calendar.selected[1] == date.getTime()) {
    return 'selection-end'
  } else if (
    calendar.selected[0] != null &&
    date.getTime() > calendar.selected[0] &&
    calendar.selected[1] != null &&
    date.getTime() < calendar.selected[1]
  ) {
    return 'selection'
  } else if (calendar.mode === 'single' && date.getMonth() !== calendar.months[0].month) {
    return 'faded'
  } else if (date.getTime() == TODAY.getTime()) {
    return 'current-day'
  }

  return 'default'
}

const Text = styled(DefaultText)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  user-select: none;
`

const Grid = styled.div`
  display: grid;
  grid-gap: ${token('space-2xs')};
  grid-template-rows: 1.25rem repeat(auto-fill, 2.5rem);
  grid-template-columns: repeat(7, 2.5rem);
`

/**
 * filling days of previous month
 * TODO: supress this for range selection
 */
function CalendarDayPicker({ calendar, onDayClick }: CalendarPickerProps): JSX.Element {
  function renderDaysOfWeek() {
    return WEEKDAYS.map((weekday) => (
      <Text
        key={weekday}
        as="abbr"
        variant="chips-sm"
        color="color-neutral"
        style={{ textDecoration: 'none' }}
      >
        {weekday}
      </Text>
    ))
  }

  function renderDaysOfMonth(month: RenderableMonth) {
    const constraints = calendar.constraints || []

    return month.days.map((day) => {
      const status = getStatus(calendar, day)
      const formatted = formatter.format(day)
      const isDisabled = day.matches(...constraints)

      if (calendar.mode === 'range' && day.getMonth() !== month.month) {
        return <span key={formatted} aria-hidden></span>
      }

      return (
        <PickerButton
          key={formatted}
          type="button"
          role="checkbox"
          aria-checked={['selection-start', 'selection', 'selection-end'].includes(status)}
          aria-label={formatted}
          disabled={isDisabled}
          onClick={() => {
            if (!isDisabled && onDayClick) {
              onDayClick(day.getTime())
            }
          }}
          $status={status}
        >
          <time dateTime={day.toString()}>{day.getDate()}</time>
        </PickerButton>
      )
    })
  }

  return (
    <Group>
      {calendar.months.map((month) => {
        return (
          <Grid key={`${month.year}${month.month}`} role="group" data-testid="calendar-day-picker">
            {renderDaysOfWeek()}
            {renderDaysOfMonth(month)}
          </Grid>
        )
      })}
    </Group>
  )
}

export default CalendarDayPicker
