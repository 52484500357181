import React, { HTMLAttributes } from 'react'
import { isNil } from '@loadsmart/utils-object'
import styled from 'styled-components'
import clsx from 'clsx'

import { Link } from '../Link'
import conditional, { whenProps } from 'tools/conditional'
import font from 'styles/font'
import { getToken as token } from 'theming'

export interface BreadcrumbProps extends HTMLAttributes<HTMLElement> {
  active?: boolean
  label: string
  url?: string
}

const StyledLink = styled(Link)`
  font-size: ${token('breadcrumbs-font-size')};
  line-height: ${token('breadcrumbs-font-height')};
`

const StyledLi = styled.li<BreadcrumbProps>`
  ${font({
    height: 'breadcrumbs-font-height',
    weight: 'breadcrumbs-font-weight',
  })}

  position: relative;

  display: inline-flex;
  align-items: center;

  font-weight: ${conditional({
    'breadcrumbs-font-weight': whenProps({ active: false }),
    'breadcrumbs-font-weight--active': whenProps({ active: true }),
  })};

  font-size: ${token('breadcrumbs-font-size')};
  line-height: ${token('breadcrumbs-font-height')};

  & + & {
    margin: 0 0 0 ${token('breadcrumbs-spacing-x')};
  }

  &:not(:only-child):not(:last-child)::after {
    position: absolute;
    left: calc(100% + 14px);

    font-weight: normal;
    font-size: ${token('breadcrumbs-font-size')};

    content: '/';
  }
`

function getWrappedLabel(props: Partial<BreadcrumbProps>) {
  const { url, label, ...rest } = props

  if (isNil(url)) {
    return label
  }

  return (
    <StyledLink href={url} target="_self" {...rest}>
      {label}
    </StyledLink>
  )
}

function Breadcrumb({ active, label, url, ...rest }: BreadcrumbProps): JSX.Element {
  return (
    <StyledLi
      className={clsx({ 'is-active': active })}
      label={label}
      data-testid="breadcrumb"
      active={active}
    >
      {getWrappedLabel({ label, url, ...rest })}
    </StyledLi>
  )
}

export default Breadcrumb
