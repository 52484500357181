import React, { ReactNode, HTMLAttributes } from 'react'

import styled from 'styled-components'
import { getToken as token } from 'theming'

export interface CardTitleProps extends HTMLAttributes<HTMLElement> {
  leading?: ReactNode
}

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`

const Leading = styled(StyledSpan)`
  margin-right: ${token('space-xs')};
`

const Wrapper = styled.div`

  padding: ${token('card-padding-y')} ${token('card-padding-x')} 0;

  color: ${token('card-color')};
  font-weight: ${token('card-title-font-weight')};
  font-size: ${token('card-title-font-size')};
  line-height: ${token('card-title-font-height')};
`

function CardTitle({ children, leading, ...others }: CardTitleProps): JSX.Element {
  return (
    <Wrapper {...others}>
      {leading && <Leading>{leading}</Leading>}
      {children}
    </Wrapper>
  )
}

export { Wrapper as CardTitleWrapper }

export default CardTitle
