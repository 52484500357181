import { IconFactory } from '../IconFactory'

import BackIcon from './assets/back.svg'
import BurguerMenuIcon from './assets/burger-menu.svg'
import CalendarIcon from './assets/calendar.svg'
import CaretDownIcon from './assets/caret-down.svg'
import CaretLeftIcon from './assets/caret-left.svg'
import CheckIcon from './assets/check.svg'
import ChevronDownIcon from './assets/chevron-down.svg'
import ChevronRightIcon from './assets/caret-right.svg'
import CircleIcon from './assets/circle.svg'
import CloseIcon from './assets/close.svg'
import InformationIcon from './assets/information.svg'
import MinusIcon from './assets/minus.svg'
import PlusIcon from './assets/plus.svg'
import SortAscIcon from './assets/sort-asc.svg'
import SortDescIcon from './assets/sort-desc.svg'
import SortIcon from './assets/sort.svg'
import WarningIcon from './assets/warning.svg'

import type { IconProps as GenericIconProps } from '../IconFactory'

const icons = {
  'burguer-menu': BurguerMenuIcon as JSX.Element,
  calendar: CalendarIcon as JSX.Element,
  'caret-down': CaretDownIcon as JSX.Element,
  'caret-left': CaretLeftIcon as JSX.Element,
  'caret-right': ChevronRightIcon as JSX.Element,
  'chevron-down': ChevronDownIcon as JSX.Element,
  back: BackIcon as JSX.Element,
  check: CheckIcon as JSX.Element,
  circle: CircleIcon as JSX.Element,
  close: CloseIcon as JSX.Element,
  information: InformationIcon as JSX.Element,
  minus: MinusIcon as JSX.Element,
  plus: PlusIcon as JSX.Element,
  sort: SortIcon as JSX.Element,
  'sort-asc': SortAscIcon as JSX.Element,
  'sort-desc': SortDescIcon as JSX.Element,
  warning: WarningIcon as JSX.Element,
}

const Icon = IconFactory(icons)

export type IconProps = GenericIconProps<typeof icons>

export default Icon
