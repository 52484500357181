import { RefObject, useEffect, useLayoutEffect, useReducer, useState } from 'react'
import { CSSProperties } from 'styled-components'

import { useDidMount } from 'hooks/useDidMount'

function updateHeight(set: (value: number) => void, el: HTMLElement | null) {
  if (el) {
    const { height } = el.getBoundingClientRect()
    set(height)
  }
}

export type HeightExpansionToggler = {
  expanded: boolean
  toggle: () => void
  height: CSSProperties['height']
}

/**
 * A React Hook to help you expand or collapse an element by
 * checking its total height and controlling a boolean flag
 * @param {React.RefObject} targetElRef - Ref of the wrapper element
 */
function useHeightExpansionToggler(targetElRef: RefObject<HTMLElement>): HeightExpansionToggler {
  const [state, toggleState] = useReducer((prevState) => !prevState, false)
  const [totalHeight, setTotalHeight] = useState<CSSProperties['height']>(0)

  useLayoutEffect(() => {
    updateHeight(setTotalHeight, targetElRef.current)
  }, [targetElRef])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      updateHeight(setTotalHeight, targetElRef.current)
    })

    if (targetElRef.current) {
      observer.observe(targetElRef.current, { childList: true })
    }

    return () => observer.disconnect()
  }, [targetElRef])

  const mounted = useDidMount()
  const finalHeight = !mounted ? 'auto' : state ? totalHeight : 0

  return {
    expanded: state,
    toggle: toggleState,
    height: finalHeight,
  }
}

export default useHeightExpansionToggler
