import React, { HTMLAttributes, ReactNode } from 'react'

import { getToken as token } from 'theming'
import CardTitle, { CardTitleWrapper } from './CardTitle'
import conditional, { whenProps } from 'tools/conditional'
import font from 'styles/font'
import Status from 'utils/types/Status'
import styled, { css } from 'styled-components'

export interface CardProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode
  status?: Status
  flagged?: boolean
}

const CardSubtitle = styled.div`
  padding: 0 ${token('card-padding-x')} ${token('card-padding-y')};

  color: ${token('card-color')};
  font-weight: ${token('card-subtitle-font-weight')};
  font-size: ${token('card-subtitle-font-size')};
`

const CardSeparator = styled.hr`
  display: block;
  height: 1px;
  margin: 0;

  border: 0;
  border-bottom: 1px solid ${token('card-separator-background')};
`

const CardBody = styled.div`
  padding: ${token('card-padding-y')} ${token('card-padding-x')};
`

const Wrapper = styled.div`
  ${font({})}

  display: flex;
  flex-direction: row;

  color: ${token('card-color')};

  ${CardTitleWrapper} + ${CardSubtitle} {
    margin-top: ${token('space-xs')};
  }

  ${CardTitleWrapper} + ${CardSeparator} {
    margin-top: ${token('card-padding-y')};
  }

  border: 1px solid ${token('card-border-color')};
  border-radius: ${token('card-border-radius')};
  background: ${token('card-background')};
`

const Container = styled.div<{ flagged: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;

  margin-left: ${conditional({
    'space-s': whenProps({ flagged: true }),
    'space-none': whenProps({ flagged: false }),
  })};

  line-height: ${token('card-font-height')};

  ${({ flagged }) =>
    flagged &&
    css`
      ${CardSeparator} {
        margin-left: calc(-1 * ${token('space-s')});
      }
    `}
`

const Flag = styled.span<{ status: Status }>`
  display: block;
  flex: 0 0 ${token('card-flag-width')};

  background: ${conditional({
    'card-flag-background-neutral': whenProps({ status: Status.Neutral }),
    'card-flag-background-danger': whenProps({ status: Status.Danger }),
    'card-flag-background-warning': whenProps({ status: Status.Warn }),
    'card-flag-background-success': whenProps({ status: Status.Success }),
  })};
  border-radius: ${token('card-border-radius')} 0 0 ${token('card-border-radius')};
`

function Card({
  flagged = false,
  status = Status.Neutral,
  children,
  ...others
}: CardProps): JSX.Element {
  return (
    <Wrapper {...others}>
      {flagged && <Flag status={status} aria-label={status} />}
      <Container flagged={flagged}>{children}</Container>
    </Wrapper>
  )
}

Card.Title = CardTitle
Card.Subtitle = CardSubtitle
Card.Separator = CardSeparator
Card.Body = CardBody

export default Card
