/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { GenericAdapter } from './Select.constants'
import { getValue, getDisplayValue, escapeRegExp } from './useSelect.helpers'
import { useDropdown } from 'components/Dropdown'
import { useFocusTrap } from 'hooks/useFocusTrap'
import { useSelectable } from './Select.context'
import to from 'utils/toolset/awaitTo'
import toArray from 'utils/toolset/toArray'
import { useDidMount } from 'hooks/useDidMount'

import type { ChangeEvent, FocusEvent } from 'react'
import type {
  GenericOption,
  Option,
  SelectAdapter,
  SelectDatasource,
  SelectDatasourceFunction,
  SelectProps,
  SelectStatus,
  useSelectReturn,
} from './Select.types'

async function* getData(datasources: SelectDatasource<any>[], query: string) {
  const regex = new RegExp(escapeRegExp(query), 'i')

  for (const ds of datasources) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [error, data] = await to(Promise.resolve(ds.fetch({ query, regex })))

    if (!error) {
      const items = (data || []).map((item) => {
        return {
          ...item,
          _type: ds.type,
        } as Option
      })

      yield items
    }
  }
}

function getDatasources(props: SelectProps) {
  function getDatasourceFromOptions(
    options?: GenericOption[] | null
  ): SelectDatasourceFunction<any>[] {
    if (!options) {
      return []
    }

    return [
      function useGenericDatasource() {
        return {
          type: 'generic',
          adapter: GenericAdapter,
          fetch: function fetch({ regex }) {
            return options.filter(({ label }) => regex.test(label))
          },
        }
      },
    ]
  }

  let datasources: SelectDatasourceFunction<any>[] = []

  datasources = datasources.concat(props.datasources || [])
  datasources = datasources.concat(getDatasourceFromOptions(props.options))

  return datasources.map((ds) => ds())
}

function extractAdapters(datasources: SelectDatasource<any>[]) {
  return toArray(datasources).reduce((map, ds) => {
    return {
      ...map,
      [ds.type]: ds.adapter,
    }
  }, {})
}

function TriggerOnFocusHandler(e: FocusEvent<HTMLInputElement>) {
  e.target.select()
}

function useOptions<T = any>(props: { datasources: SelectDatasource<T>[] }) {
  const { datasources } = props
  const [options, setOptions] = useState<Option[]>([] as Option[])
  const [status, setStatus] = useState<SelectStatus>('idle')

  const timeoutRef = useRef<NodeJS.Timeout>()

  function cancelPendingFetch() {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current)
    }
  }

  const fetchAfterTimeout = useCallback(
    function fetchAfterTimeout(query: string) {
      cancelPendingFetch()

      async function fetch() {
        setOptions([])
        setStatus('querying')

        for await (const items of getData(datasources, query)) {
          setOptions((options) => [...options, ...(items || [])])
        }

        setStatus('idle')
      }

      timeoutRef.current = setTimeout(() => void fetch(), 750)
    },
    [datasources]
  )

  const clear = useCallback(function clear() {
    setOptions([])
  }, [])

  const get = useCallback(
    function get() {
      return options
    },
    [options]
  )

  useEffect(() => {
    return () => {
      cancelPendingFetch()
    }
  }, [])

  return {
    get,
    fetch: fetchAfterTimeout,
    clear,
    status,
  }
}

// TODO: keep adapter resolution in a single place
// TODO: prevent state changes after unmount (`useMounted` hook from Alice Frontend)
/**
 * Based on https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html
 * @param props
 * @returns
 */
function useSelect(props: SelectProps): useSelectReturn {
  const didMount = useDidMount()
  const { multiple, onQueryChange, onChange, id, name, disabled = false } = props
  const dropdown = useDropdown(props)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const datasources = useMemo<SelectDatasource<any>[]>(() => getDatasources(props), [
    props.datasources,
    props.options,
  ])
  const adapters = useMemo<Record<string, SelectAdapter<any>>>(() => extractAdapters(datasources), [
    datasources,
  ])

  const selectable = useSelectable({
    selected: toArray(props.value || []),
    multiple,
    adapters,
    onChange: useCallback(
      function handleSelectionChange(selected) {
        onChange?.({ target: { id, name, value: getValue(selected, multiple) } })
      },
      [id, multiple, name, onChange]
    ),
  })

  const triggerRef = useRef<HTMLInputElement>()
  const focusTrap = useFocusTrap({
    keys: ['ARROW_UP', 'ARROW_DOWN'],
    onDeactivate() {
      triggerRef.current?.focus()
    },
  })

  const [query, setQuery] = useState<string>(
    getDisplayValue(adapters, selectable.selected, multiple)
  )
  const options = useOptions({ datasources })

  const getOption = useCallback(
    function getOption(option: Option) {
      const adapter = adapters[option._type || ''] || GenericAdapter

      const value = String(adapter.getKey(option))
      const label = adapter.getLabel(option)
      const checked = selectable.selected.has(value)

      return { label, value, checked }
    },
    [adapters, selectable.selected]
  )

  const toggleOption = useCallback(
    function toggleOption(option: Option) {
      selectable.toggle(option)
    },
    [selectable]
  )

  const getDropdownProps = useCallback(
    function getDropdownProps() {
      return {
        toggle: dropdown.toggle,
        expanded: dropdown.expanded,
        onBlur() {
          if (!multiple) {
            setQuery(getDisplayValue(adapters, selectable.selected, multiple))
          } else {
            setQuery('')
          }
          options.fetch('')
        },
      }
    },
    [adapters, dropdown.expanded, dropdown.toggle, multiple, options, selectable.selected]
  )

  const getTriggerProps = useCallback(
    function getTriggerProps() {
      return {
        id,
        ref(node: HTMLInputElement | null) {
          if (node != null) {
            triggerRef.current = node
          }
        },
        value: query,
        onChange(e: ChangeEvent<HTMLInputElement>) {
          onQueryChange?.(e)

          const query = e.target.value
          setQuery(query)
          dropdown.expand()
          options.fetch(query)
        },
        onFocus: TriggerOnFocusHandler,
      }
    },
    [id, query, onQueryChange, dropdown, options]
  )

  const getClearProps = useCallback(
    function getClearProps() {
      return {
        onClick() {
          setQuery('')
          selectable.clear()
          options.clear()

          triggerRef.current?.focus()

          options.fetch('')
        },
      }
    },
    [options, selectable]
  )

  const getMenuProps = useCallback(
    function getMenuProps() {
      return {
        ref: focusTrap.containerRef,
        role: 'listbox',
      }
    },
    [focusTrap.containerRef]
  )

  const getOptionProps = useCallback(
    function getOptionProps({ option }: { option: Option }) {
      const { value, checked, label } = getOption(option)

      return {
        role: 'option',
        'aria-selected': checked,
        id: value,
        onClick() {
          if (!multiple) {
            setQuery(checked ? '' : label)
          }
          toggleOption(option)

          return multiple
        },
        tabIndex: -1,
      }
    },
    [getOption, toggleOption, multiple]
  )

  useEffect(
    function onInit() {
      options.fetch('')
    },
    // we just want to load any initial options that a datasource may have available
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasources]
  )

  useEffect(
    function onDropdownToggle() {
      if (!props.autoFocus && !didMount) {
        return
      }
      if (dropdown.expanded) {
        focusTrap.activate()
      } else {
        focusTrap.deactivate()
      }
    },
    /**
     * We are interested in activating/deactivating our
     * focus trap when the dropdown changes its expanded state.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropdown.expanded]
  )

  useEffect(
    function updateOnSelectedChange() {
      if (!multiple) {
        setQuery(getDisplayValue(adapters, selectable.selected, multiple))
      }
    },
    [adapters, multiple, selectable.selected]
  )

  return {
    status: options.status,
    options: options.get(),
    value: getValue(selectable.selected, multiple),
    query,
    disabled,

    selectable,

    getMenuProps,
    getOption,
    getOptionProps,
    getTriggerProps,
    getClearProps,
    getDropdownProps,
  }
}

export default useSelect
