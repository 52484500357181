import React, { Fragment, PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'

import { getPanelRadioID } from './Steps.helpers'
import { getToken as token } from 'theming'
import font from 'styles/font'
import hidden from 'styles/hidden'
import StepsContext from './StepsContext'

export interface StepsStepProps {
  id: number | string
  className?: string
}

const Selector = styled.input`
  ${hidden(true)}
`

export const StepWrapper = styled.article`
  ${font({
    weight: 'font-weight-medium',
    height: 'font-height-2',
  })}

  padding: 0 ${token('steps-step-padding-x')} ${token('steps-step-padding-y')};

  ${Selector} + & {
    display: none;
  }

  ${Selector}:checked + & {
    display: block;
  }
`

function StepsStep({ children, id, ...others }: PropsWithChildren<StepsStepProps>): JSX.Element {
  const StepsID = useContext(StepsContext)

  return (
    <Fragment>
      <Selector
        type="radio"
        className="visually-hidden"
        id={getPanelRadioID(id)}
        name={String(StepsID)}
        value={id}
        data-testid="panel-radio"
        tabIndex={-1}
      />
      <StepWrapper data-testid="steps-step" {...others} id={String(id)}>
        {children}
      </StepWrapper>
    </Fragment>
  )
}

export default StepsStep
