import { useCallback, useEffect, useState } from 'react'

import KeyboardKey from 'utils/toolset/keyboard'

import type { RefObject } from 'react'

function useClickOutside<T extends HTMLElement>(
  container: RefObject<T>,
  callback: () => void,
  disabled = false
): void {
  const [active, setActive] = useState(false)

  const getContainer = useCallback(
    function getContainer() {
      return container.current
    },
    [container]
  )

  const handleEvent = useCallback(
    function handleEvent(e: Event) {
      function hasPressedEsc() {
        return KeyboardKey((e as unknown) as React.KeyboardEvent).is('ESCAPE')
      }

      if (!getContainer() || disabled) {
        return
      }

      const target = e.target as Node
      const hasTarget = getContainer()?.contains(target)

      if (!active && hasTarget) {
        setActive(true)
      } else if (active && (!hasTarget || hasPressedEsc())) {
        setActive(false)
        callback()
      }
    },
    [active, callback, disabled, getContainer]
  )

  useEffect(() => {
    function subscribe() {
      document.addEventListener('mousedown', handleEvent, true)
      document.addEventListener('touchend', handleEvent, true)
      document.addEventListener('keyup', handleEvent)
    }

    function unsubscribe() {
      document.removeEventListener('mousedown', handleEvent, true)
      document.removeEventListener('touchend', handleEvent, true)
      document.removeEventListener('keyup', handleEvent)
    }

    subscribe()

    return () => {
      unsubscribe()
    }
  }, [handleEvent])
}

export default useClickOutside
