import React from 'react'
import styled from 'styled-components'

import conditional, { whenProps } from 'tools/conditional'
import prop from 'tools/prop'

import { Spacing } from './Layout.types'
import { getSpace } from './Layout.utils'

import type { HTMLAttributes } from 'react'

export interface SidebarProps extends HTMLAttributes<HTMLElement> {
  space?: Spacing
  reversed?: boolean
  sideWidth?: string
  minContentWidth?: string
}

const SidebarContainer = styled.div<{
  $space: SidebarProps['space']
  $reversed: SidebarProps['reversed']
  $sideWidth: SidebarProps['sideWidth']
  $minContentWidth: SidebarProps['minContentWidth']
}>`
  display: flex;
  flex-wrap: wrap;

  gap: ${getSpace('$space')};

  &
    > ${conditional({
      ':first-child': whenProps({ $reversed: false }),
      ':last-child': whenProps({ $reversed: true }),
    })} {
    flex-basis: ${prop('$sideWidth')};
    flex-grow: 1;
  }

  &
    > ${conditional({
      ':first-child': whenProps({ $reversed: true }),
      ':last-child': whenProps({ $reversed: false }),
    })} {
    flex-basis: 0;
    flex-grow: 999;
    min-width: ${prop('$minContentWidth')};
  }
`

/**
 * Sidebar layout component.
 * @param {SidebarProps} props
 */
export function Sidebar(props: SidebarProps): JSX.Element {
  const {
    minContentWidth = '50%',
    reversed = false,
    sideWidth = 'auto',
    space = 'm',
    children,
    ...rest
  } = props

  return (
    <SidebarContainer
      $minContentWidth={minContentWidth}
      $reversed={reversed}
      $sideWidth={sideWidth}
      $space={space}
      {...rest}
    >
      {children}
    </SidebarContainer>
  )
}
