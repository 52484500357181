import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import conditional, { whenProps } from 'tools/conditional'
import { Spacing } from './Layout.types'
import { getSpace } from './Layout.utils'

import type { HTMLAttributes } from 'react'

export interface BoxProps extends HTMLAttributes<HTMLElement> {
  background?: 'transparent' | 'neutral-white' | 'neutral-lighter' | 'neutral-lightest'
  padding?: Spacing
  borderRadius?: 'none' | 's' | 'm' | 'l'
  borderWidth?: 'medium' | 'thin' | 'none'
  borderColor?: 'neutral-darker' | 'neutral-light' | 'neutral-lighter' | 'neutral-white'
}

const BoxContainer = styled.div<{
  $background: BoxProps['background']
  $padding: BoxProps['padding']
  $borderRadius: BoxProps['borderRadius']
  $borderWidth: BoxProps['borderWidth']
  $borderColor: BoxProps['borderColor']
}>`
  padding: ${getSpace('$padding')};

  color: ${token('color-neutral-darker')};

  background-color: ${conditional({
    'color-transparent': whenProps({ $background: 'transparent' }),
    'color-neutral-white': whenProps({ $background: 'neutral-white' }),
    'color-neutral-lighter': whenProps({ $background: 'neutral-lighter' }),
    'color-neutral-lightest': whenProps({ $background: 'neutral-lightest' }),
  })};

  border-color: ${conditional({
    'color-neutral-darker': whenProps({ $borderColor: 'neutral-darker' }),
    'color-neutral-light': whenProps({ $borderColor: 'neutral-light' }),
    'color-neutral-lighter': whenProps({ $borderColor: 'neutral-lighter' }),
    'color-neutral-white': whenProps({ $borderColor: 'neutral-white' }),
  })};

  border-style: solid;

  border-width: ${conditional({
    'border-width-medium': whenProps({ $borderWidth: 'medium' }),
    'border-width-thin': whenProps({ $borderWidth: 'thin' }),
    'border-width-none': whenProps({ $borderWidth: 'none' }),
  })};

  border-radius: ${conditional({
    'border-radius-none': whenProps({ $borderRadius: 'none' }),
    'border-radius-s': whenProps({ $borderRadius: 's' }),
    'border-radius-m': whenProps({ $borderRadius: 'm' }),
    'border-radius-l': whenProps({ $borderRadius: 'l' }),
  })};
`

/**
 * Box layout component.
 * @param {BoxProps} props
 */
export function Box(props: BoxProps): JSX.Element {
  const {
    background = 'transparent',
    borderColor = 'neutral-light',
    borderRadius = 's',
    borderWidth = 'none',
    padding = 'm',
    children,
    ...rest
  } = props

  return (
    <BoxContainer
      $background={background}
      $borderColor={borderColor}
      $borderRadius={borderRadius}
      $borderWidth={borderWidth}
      $padding={padding}
      {...rest}
    >
      {children}
    </BoxContainer>
  )
}
