import React from 'react'
import styled from 'styled-components'

import { BaseButton } from 'components/Button'
import { Icon } from 'components/Icon'
import { getToken as token } from 'theming'
import focusable from 'styles/focusable'

import type { ButtonProps } from 'components/Button'

export interface CloseButtonProps extends ButtonProps {
  size?: string | number
}

const Button = styled(BaseButton)`
  border: none;
  border-radius: ${token('border-radius-circle')};

  color: ${token('color-neutral-darker')};

  background: ${token('color-transparent')};

  padding: ${token('space-xs')};

  font-size: ${token('font-size-3')};

  height: 2em;
  width: 2em;

  ${focusable`
    box-shadow: ${token('button-icon-outline')};
  `}
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CloseButton({ scale = 'small', size = 16, ...others }: CloseButtonProps): JSX.Element {
  return (
    <Button scale={scale} {...others}>
      <Icon name="close" size={size} />
    </Button>
  )
}

export default CloseButton
